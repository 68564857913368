
// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


import Footer from "examples/Footer";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { TechChecks } from "examples/Technologies";
import IrDahshboardLayout from "components/IrDashboard";
import { useGetScoreQuery } from "services/invisirisk_api";
import Checks from "components/Checks";
import { testData } from "./test-data";
import { Grid } from "@mui/material";


function ScoreTable() {
  const testMode = process.env.REACT_APP_TEST ? true : false;

  let location = useLocation();

  let params = useParams();

  let response = useGetScoreQuery({ type: params.type, version: params.version, name: params["*"] })
  if (testMode) {
    response = testData()
  }
  if (response.isLoading || response.error) {
    return <div>Loading...</div>
  }

  return (
    <IrDahshboardLayout>
      <Box pt={0.25} pb={1}>

        <Grid container spacing={2} direction="column">
          <Grid item >
            <Card>
              <Box p={3} lineHeight={1}>
                <Typography variant="h5" fontWeight="medium">
                  {params["*"]}
                </Typography>

              </Box>
            </Card>
          </Grid>
          {
            location.state.data.checks != null && location.state.data.checks.length > 0 &&
            <Grid item >

              <Card>
                <Box style={{ display: 'flex' }}>
                  <Box style={{ flexGrow: 1 }}>

                    <TechChecks checks={location.state.data.checks}></TechChecks>
                  </Box>
                </Box>
              </Card>
            </Grid  >

          }
          {
            response.data != null && response.data.checks.length > 0 &&
            <Grid item >
              <Card>
                <Checks checks={response.data.checks}></Checks>
              </Card>
            </Grid>
          }
        </Grid>
        <Footer />
      </Box>
    </IrDahshboardLayout>
  );
}

export default ScoreTable;

import ReactSpeedometer from "react-d3-speedometer"

export default function ScoreGuage({ score }) {
    return (
        <ReactSpeedometer
            maxValue={10}
            value={score}
            startColor="red"
            segments={10}
            endColor="green"
            needleColor="steelblue"
            fluidWidth={true}
        />
    )
}
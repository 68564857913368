
// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import { Line } from 'react-chartjs-2';


import { DataGrid } from '@mui/x-data-grid';


import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { Technologies } from "examples/Technologies";

import IrDahshboardLayout from "components/IrDashboard";
import { useGetProjectQuery } from 'services/invisirisk_api';
import { ScoreChart } from 'components/ScoreChart';
import Alerts from "components/Alerts";

import test_data from "./test-data";


function LineChart({ chart }) {
  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      x: {
        ticks: {
          display: false
        },
        min: 0,
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 10,
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },

    },
  };
  return <Line options={options} data={chart} />;

}


function ProjectTables() {
  const location = useLocation()
  const path = location.pathname;
  const parts = path.split('projects/')
  let projectName = ""
  if (parts.length > 1) {
    projectName = parts[1]
  }

  const columns = [
    {
      headerName: "Start Time",
      field: "startTime",
      flex: 1,
      renderCell: (d) => {
        const original = d.row
        return <Link underline="always" href={`/pages/projects/${projectName}/builds/${original.id}`}>{original.startTime}</Link>
      },

    },
    {
      headerName: "Build URL",
      field: "buildUrl",
      flex: 1,
      renderCell: (params) => {
        const original = params.row
        return <a href={`${original.buildUrl}`} target="_blank" rel="noreferrer">{original.buildUrl}</a>

      }
    },
    /*
    {
      headerName: "Status",
      field: "status",
      width: 100
    },
    */
    {
      headerName: "Score",
      field: "minScore",
      width: 100,
      renderCell: (params) => {
        return (
          <ScoreChart value={params.value}></ScoreChart>
        )
      }


    }
  ]

  let navigate = useNavigate()

  const onClick = (id) => {
    let build = scoreData.labels[id];
    let l = "/pages/projects/" + projectName + "/builds/" + build;
    navigate(l);
  }
  let response = useGetProjectQuery(projectName)
  const testMode = process.env.REACT_APP_TEST ? true : false;
  if (testMode) {
    response = test_data()
  }
  if (response.isLoading || response.error) {
    return <div>  </div>
  }

  let scoreColumns = []
  let scoreRows = []
  response.data.scoreHistory.forEach(sh => {
    scoreColumns.push(sh.buildId)
    if (sh.score) {
      scoreRows.push(sh.score)
    } else {
      scoreRows.push(0)
    }
  })

  const scoreData = ({
    labels: scoreColumns,
    datasets: [{
      label: "Score History",
      data: scoreRows,
      fill: true,
      backgroundColor: 'rgba(0, 68, 38, 0.3)',

    }]
  })

  let actColumns = []
  let actRows = []
  response.data.activityHistory.forEach(sh => {
    actColumns.push(sh.buildId)
    if (sh.activityCount) {
      actRows.push(sh.activityCount)
    } else {
      actRows.push(0)
    }
  })
  const activityData = ({
    labels: actColumns,
    datasets: [{
      label: "Activity Count",
      data: actRows,
      fill: true,
      backgroundColor: 'rgba(0, 68, 38, 0.3)',

    }]
  })
  const treeData = {
    builds: {
      columns: columns,
      rows: response.data.builds
    },
    technologies: response.data.technologies ? response.data.technologies : []
  }
  return (
    <IrDahshboardLayout>
      <Box pt={0.25} pb={1}>
        {/** Top level container */}
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Card>
                      <LineChart
                        title="Score History"
                        chart={scoreData}
                        onClick={onClick}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <LineChart
                        title="Activity History"
                        chart={activityData}
                        onClick={onClick}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                {
                  <Card style={{ height: '100%' }}>
                    <Technologies data={treeData.technologies} limit={10} project={projectName}></Technologies>
                  </Card>
                }
              </Grid>
              <Grid item xs={12}>
                <Card >
                  <Box style={{ display: 'flex' }}>
                    <Box style={{ flexGrow: 1 }}>
                      <DataGrid
                        autoHeight
                        columns={columns}
                        rows={treeData.builds.rows}
                        table={treeData.builds} canSearch />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Alerts alerts={response.data.alerts}></Alerts>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </IrDahshboardLayout>
  )
}

export default ProjectTables;

import Card from "@mui/material/Card";
import { DataGrid } from '@mui/x-data-grid';
import { AlertIcon } from "components/Icons";
import { ScoreChart } from 'components/ScoreChart';

export default function Checks({ checks, hideAlert }) {
    const columns = [
        {
            headerName: 'Alert',
            field: 'alertLevel',
            hide: hideAlert,
            renderCell: (params) => {
                return (
                    <AlertIcon alert={params.value}></AlertIcon>
                )
            }
        },
        {
            headerName: 'Name',
            field: 'name',
            width: 200,

        },
        {
            headerName: 'Score',
            field: 'score',
            width: 100,
            renderCell: (params) => {
                return (
                    <ScoreChart value={params.value}></ScoreChart>
                )
            }

        },
        {
            headerName: 'Reason',
            field: 'reason',
            flex: 1

        }
    ]
    return (

        <Card sx={{ display: 'flex', height: '100%' }}>
            <DataGrid
                hideFooter
                rows={checks}
                autoHeight
                columns={columns}
                pageSize={10}
                getRowHeight={() => 'auto'}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.name}

            />
        </Card>
    )

}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


// Define a service using a base URL and expected endpoints
export const invisiriskApi = createApi({
    reducerPath: 'invisiriskApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://app.ir.dev.veribom.com/api' }),
    endpoints: (builder) => ({
        getAvgScore: builder.query({
            query: () => `/avg-score`,
        }),
        getGhInstallCheck: builder.query({
            query: (name) => `gh-install-check`,
        }),
        addGhInstall: builder.mutation({
            query: (installId) => ({
                method: 'POST',
                body: { installation_id: parseInt(installId) },
                url: `gh-install`,
            }),
        }),
        getRepo: builder.query({
            query: (name) => name ? `repos/${name}` : "repos",
        }),
        getBuild: builder.query({
            query: (build) => `/projects/${build.project}/builds/${build.build}`,
        }),
        getScore: builder.query({
            query: (pkg) => `/score/${pkg.type}/${pkg.version}/${pkg.name}`,
        }),
        getProjects: builder.query({
            query: () => `/projects`,
        }),
        getProject: builder.query({
            query: (project) => `/projects/${project}`,
        })
    }),
})


export const { useGetGhInstallCheckQuery,
    useGetRepoQuery,
    useGetBuildQuery,
    useGetScoreQuery,
    useGetProjectsQuery,
    useGetProjectQuery,
    useAddGhInstallMutation,
    useGetAvgScoreQuery
} = invisiriskApi

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';

// react-router components
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";


// Material Dashboard 2 PRO React themes
import theme from "assets/theme";


// Material Dashboard 2 PRO React routes
import routes from "routes";


import SuperTokens, { SuperTokensWrapper, getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import ThirdParty, { Github } from "supertokens-auth-react/recipe/thirdparty";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";

import * as reactRouterDom from "react-router-dom";

import MenuAppBar from 'app/appbar';
import { MaterialUIControllerProvider } from "context";
import { store } from './app/store'
import { Provider } from 'react-redux'

const testMode = process.env.REACT_APP_TEST ? true : false;

function SuperTokeInit() {

  let apiDomain = "https://app.ir.dev.veribom.com"
  let websiteDomain = "https://app.ir.dev.veribom.com"
  if (testMode) {
    apiDomain = "http://localhost:3000"
    websiteDomain = "http://localhost:3000"
  }

  SuperTokens.init({
    appInfo: {
      appName: "InvisiRisk",
      apiDomain: apiDomain,
      websiteDomain: websiteDomain,
      apiBasePath: "/api",
      websiteBasePath: "/auth"
    },
    recipeList: [
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [
            Github.init(),

          ]
        }
      }),
      Session.init()
    ]
  });
}

SuperTokeInit()


export default function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (testMode) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
        return <Route exact path={route.route} element={<SessionAuth>{route.component}</SessionAuth>} key={route.key} />;

      }
      return null;
    });
  return (
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Provider store={store}>

          <SuperTokensWrapper>
            <React.StrictMode>
              <MenuAppBar />

              <ThemeProvider theme={theme}>

                <Routes>
                  {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
                  {getRoutes(routes)}
                  <Route path="*" element={<Navigate to="/gh-install" />} />
                </Routes>
              </ThemeProvider>

            </React.StrictMode>
          </SuperTokensWrapper>
        </Provider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  );
}



/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/




import ProjectsTables from "layouts/applications/projects-table";
import ProjectTables from "layouts/applications/project-table";
import BuildTable from "layouts/applications/build-table";
import ScoreTable from "layouts/applications/score-table";
import Repos from "layouts/applications/repos";
import RepoList from "layouts/applications/repo-list";


import GhInstall, { GhInstallComplete } from "app/gh-install";

// @mui icons
import Icon from "@mui/material/Icon";
import TechCheckTable from "layouts/applications/tech-checks";

const routes = [


  {
    name: "GH-Install",
    key: "ghinstall",
    route: "/gh-install",
    component: <GhInstall />,

  },
  {
    name: "GH-Install-Complete",
    key: "ghinstall",
    route: "/gh-installed",
    component: <GhInstallComplete />,

  },
  {
    name: "Project",
    key: "project",
    route: "/pages/projects/:project",
    component: <ProjectTables />,
  },
  {
    name: "Build",
    key: "build",
    route: "/pages/projects/:project/builds/:build",
    component: <BuildTable />,
  },
  {
    name: "Score",
    key: "score",
    route: "/pages/projects/:project/builds/:build/score/:type/:version/*",
    component: <ScoreTable />,
  },
  {
    name: "Technology Checks",
    key: "tech-checks",
    route: "/pages/projects/:project/techchecks",
    component: <TechCheckTable />,
  },

  {
    type: "collapse",
    name: "Projects",
    key: "projects-group",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Projects",
        key: "projects",
        route: "/pages/projects",
        component: <ProjectsTables />,
      },
      {
        name: "Repositories",
        key: "repos",
        route: "/pages/repositories/*",
        component: <Repos />,
      },
      {
        name: "RepoList",
        key: "repoList",
        route: "/pages/repositories",
        component: <RepoList />,
      },
    ],
  },




];

export default routes;

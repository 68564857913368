// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 PRO React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";


// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";
import { DataGrid } from '@mui/x-data-grid';

import IrDahshboardLayout from "components/IrDashboard";
import { useGetProjectsQuery } from 'services/invisirisk_api';


function LinkButton({ project }) {
  return (
    <Link underline='always' href={'/pages/projects/' + project} > {decodeURIComponent(project)}</Link >
  )
}

function ProjectsTable() {
  const testMode = process.env.REACT_APP_TEST ? true : false;



  let response = useGetProjectsQuery()
  if (testMode) {
    response = {
      data: [{ "project": "object-download-demo-build", "buildCount": 5, "builds": null, "technologies": null, "scoreHistory": null, "activityHistory": null, "techScoreHistory": null },
      { "project": "secret-leak-build", "buildCount": 14, "builds": null, "technologies": null, "scoreHistory": null, "activityHistory": null, "techScoreHistory": null },
      { "project": "shadow-it-build", "buildCount": 12, "builds": null, "technologies": null, "scoreHistory": null, "activityHistory": null, "techScoreHistory": null }
      ],
      isLoading: false,
      error: null
    }
  }
  if (response.isLoading || response.error) {
    return <div>Loading...</div>
  }


  const ncolumns = [

    {
      field: 'project',
      headerName: 'Projects',
      sortable: false,
      flex: 1,
      renderCell: (d) => {
        return (
          <LinkButton project={d.value} />
        )
      },
    },
    {
      field: 'buildCount',
      headerName: 'Build Count',
      sortable: false,
      flex: 1,
    },
  ];

  return (
    < IrDahshboardLayout >
      <Card>
        <Box p={3} lineHeight={1}>
          <Typography variant="h5" fontWeight="medium">
            Your pipelines
          </Typography>

        </Box>
        <Card m={2}>
          <Box style={{ display: 'flex', height: '100vh' }}>
            <Box style={{ flexGrow: 1 }}>
              <DataGrid
                getRowId={(row) => row.project}
                rows={response.data}
                columns={ncolumns}
              />
            </Box>
          </Box>
        </Card>
      </Card>
      <Footer />
    </IrDahshboardLayout >
  );
}

export default ProjectsTable;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetGhInstallCheckQuery, useAddGhInstallMutation } from 'services/invisirisk_api';
import { useSearchParams } from "react-router-dom";


function GhInstall() {
    let navigate = useNavigate()
    const { _, error, isLoading } = useGetGhInstallCheckQuery();
    return (
        <div>
            {(isLoading) && <div />}
            {(!isLoading && !error) && navigate("/pages/Repositories/new")}
            {(error) && <div>{window.location.href = "https://github.com/apps/invisirisk/installations/new/"} </div>}
        </div>
    )
}

export function GhInstallComplete() {
    const [searchParams] = useSearchParams();
    const installId = searchParams.get("installation_id")
    let navigate = useNavigate()
    const [updatePost, result] = useAddGhInstallMutation()

    useEffect(() => {
        updatePost(installId)
            .unwrap().then()
            .catch(rejected => console.error("error reporting installationId", rejected))

        navigate("/pages/Repositories/new")
    }, [navigate, updatePost, installId])


    return (<></>)
}

export default GhInstall;

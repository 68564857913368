import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";



function BuildInfoCard({ title, info }) {
    const labels = [];
    const values = [];


    // Convert this form `objectKey` of the object key in to this `object key`
    Object.keys(info).forEach((el) => {
        labels.push(el);
    });

    // Push the object values into the values array
    Object.values(info).forEach((el) => values.push(el));

    // Render the card info items
    const renderItems = labels.map((label, key) => (
        <Box key={label} display="flex" py={1} pr={2}>
            <Typography variant="button" fontWeight="bold" >
                {label}: &nbsp;
            </Typography>
            <Typography variant="body2" fontWeight="regular" color="text">
                &nbsp;{values[key]}
            </Typography>
        </Box>
    ));



    return (
        <Card>
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <Typography variant="h6" fontWeight="medium" >
                    {title}
                </Typography>

            </Box>
            <Box p={2}>
                <Box opacity={0.3}>
                </Box>
                <Box>
                    {renderItems}
                </Box>
            </Box>
        </Card>
    );
}



export default BuildInfoCard;

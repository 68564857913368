
import React, { useEffect } from 'react';// MUI components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from "@mui/material";

import { Radar } from 'react-chartjs-2';

// React components
import { useNavigate, useParams } from 'react-router-dom'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    BarElement,
    Filler,
    Title,
} from 'chart.js';

import Footer from "examples/Footer";

import testData, { avgTestData } from './data';
import Dependencies from "./dependencies";
import Alerts from "components/Alerts";
import { useGetRepoQuery } from 'services/invisirisk_api';
import { ScoreCharts, BarChart } from "./charts";
import IrDahshboardLayout from "components/IrDashboard";
import ScoreGuage from "./guage"
import { useGetAvgScoreQuery } from 'services/invisirisk_api';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    Filler,
    Title,
    BarElement);

function RadialChart({ checks, industry }) {

    let labels = checks.map(d => d.name)
    let values = checks.map(d => d.score)
    let industryValues = labels.map(l => {
        let v = industry.find(d => d.name === l)
        if (v) {
            return v.score
        }
        return 0
    })

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Industry',
                data: industryValues,
                borderWidth: 1,
                fill: true,
                backgroundColor: 'rgba(68, 00,31, 0.3)',
            },
            {
                label: 'Self Score',
                data: values,
                borderWidth: 1,
                fill: true,
                backgroundColor: 'rgba(0, 68, 38, 0.3)',

            },

        ],
    };
    return (
        <Card>
            <Radar data={data} />
        </Card>
    )
}

function RepoMenu({ repos }) {
    const navigate = useNavigate()

    return (
        <Autocomplete
            freeSolo
            id="combo-box-demo"
            options={repos}
            getOptionLabel={(d) => d.repoPath}
            sx={{ width: 300 }}
            onChange={(e, v) =>
                navigate(`/pages/Repositories/${v.repoPath}`)
            }
            renderInput={(params) => <TextField {...params} label="Repository" />}
        />
    )
}

function averageDependencyScore(dependencies) {
    let sum = 0;
    let count = 0;
    dependencies.forEach(d => {
        if (d.score) {
            sum += d.score;
            count++;
        }
    })
    return sum / count;
}

export default function Repos() {

    const params = useParams()
    const navigate = useNavigate()

    const repo = params['*']
    const testMode = process.env.REACT_APP_TEST ? true : false;

    let avgScore = useGetAvgScoreQuery()

    let { data, error, isLoading } = useGetRepoQuery(repo, { skip: repo === "new" });
    let scoreData = data;

    const rdata = useGetRepoQuery();
    if (testMode) {
        scoreData = testData();
        isLoading = false;
        error = false;
        rdata.data = [
            {
                "providerName": "github",
                "repoPath": "github.com/usirsiwal/testrepo",
                "analytics": null
            },
            {
                "providerName": "github",
                "repoPath": "github.com/usirsiwal/godep",
                "analytics": null
            }
        ]
        rdata.isLoading = false;
        rdata.error = false;
        avgScore = avgTestData()

    }


    useEffect(() => {
        if (rdata.isLoading || rdata.error || !rdata.data) {
            return;
        }
        if (repo === "new" && rdata.data.length > 0) {
            navigate(`/pages/Repositories/${rdata.data[0].repoPath}`)
        }
    }, [rdata, repo, navigate])
    if (rdata.isLoading || rdata.error || !rdata.data) {
        return <></>
    }
    if (isLoading || error || !scoreData) {
        return <></>
    }

    if (avgScore.isLoading || avgScore.error || !avgScore.data || !avgScore.data.score) {
        return <></>
    }

    let depencyScore = averageDependencyScore(scoreData.dependencies);

    return (
        <IrDahshboardLayout>
            <Box pt={0.25} pb={1}>
                <RepoMenu repos={rdata.data}></RepoMenu>
                <Typography variant="h5" m={4}>{repo}</Typography>

                {/** Top level container */}
                <Grid container spacing={2}>
                    <Grid item xs={8} >


                        {/** charts  */}
                        <Grid container>
                            <Grid item xs={6} style={{ height: '100%' }}>

                                <Grid container>

                                    <Grid item xs={12} p={2}>
                                        <Card >
                                            <Typography variant="h5">Score Summary</Typography>

                                            <Grid container>
                                                <Grid item xs={4} height={100}>
                                                    <ScoreGuage score={avgScore.data.score.toFixed(2)}></ScoreGuage>
                                                    <Typography variant="h6" m={1} sx={{ align: "center" }}>Industry</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ScoreGuage score={scoreData.selfScore.score}></ScoreGuage>
                                                    <Typography variant="h6" m={1} sx={{ align: "center" }}>Score</Typography>
                                                </Grid>
                                                <Grid item xs={4} height={100}>
                                                    <ScoreGuage score={depencyScore}></ScoreGuage>
                                                    <Typography variant="h6" m={1} sx={{ align: "center" }}>Dependencies</Typography>
                                                </Grid>
                                            </Grid>


                                        </Card>

                                    </Grid>
                                    <Grid item xs={12} p={2}>
                                        <Card>
                                            <RadialChart sx={{ height: "20%" }} checks={scoreData.selfScore.checks} industry={avgScore.data.checks} />
                                        </Card>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={6} sx={{ maxHeight: "85vh", overflow: "scroll" }}>
                                <Typography variant="h5">Dependency Score Distribution</Typography>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <BarChart buckets={scoreData.analytics.buckets[0]} industry={avgScore.data.buckets} />
                                    </Grid>
                                    <ScoreCharts scores={scoreData.analytics.buckets.slice(1)} industry={avgScore.data.checkBuckets}></ScoreCharts>
                                </Grid>
                            </Grid>

                        </Grid>
                        {/* dependencies */}
                        <Grid item xs={12} >
                            <Card>
                                <Typography variant="h5">Score Details</Typography>

                            </Card>
                            <Card>
                                <Dependencies scoreData={scoreData}></Dependencies>
                            </Card>
                        </Grid>
                    </Grid>
                    {

                        <Grid item xs={4} lg={true} lx={true} sm={true}>
                            <Box sx={{ maxHeight: "120vh", overflow: "scroll" }}>
                                <Typography variant="h5">Alerts</Typography>
                                <Alerts alerts={scoreData.alerts}></Alerts>
                            </Box>
                        </Grid>
                    }
                </Grid>

            </Box >
            <Footer />
        </IrDahshboardLayout >
    );
}
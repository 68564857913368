import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";

import { IconContext } from "react-icons";
import { Typography } from "@mui/material";
import { AlertIcon, EcoIcon } from 'components/Icons';
import { AlertColors } from "components/Icons";




function AlertCard({ alert }) {
    const cols = AlertColors()
    let level = alert.alertLevel
    if (level === "") {
        level = "success"
    }
    const bg = cols[level] + "30"
    return (
        <Card sx={{ background: bg }}>
            <Box p={0.5}>
                <Grid container direction={"row"} spacing={2}>

                    <Grid item>
                        <AlertIcon alert={alert.alertLevel}></AlertIcon>
                    </Grid>
                    <Grid item>
                        <EcoIcon eco={alert.ecosystem} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">{alert.target}</Typography>
                    </Grid>
                </Grid>

                <Typography paragraph={true} variant="body2">{alert.details}</Typography>
            </Box>
        </Card>
    )
}

export default function Alerts({ alerts }) {
    const alertTypes = {
        "crit": 1,
        "error": 2,
        "warn": 3,
        "": 4,
    }
    alerts = alerts.slice().sort((a, b) => {
        return alertTypes[a.alertLevel] - alertTypes[b.alertLevel]
    })
    return (
        <Grid container direction={"column"} spacing={2} >
            <IconContext.Provider value={{ size: "2em", className: "global-class-name" }}>
                {
                    alerts.map((m, id) => (
                        <Grid item key={id} >
                            <AlertCard alert={m} />
                        </Grid>
                    ))
                }
            </IconContext.Provider>
        </Grid>

    )
}

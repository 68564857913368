import { useMemo } from 'react'
import Link from '@mui/material/Link';
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { AlertIcon } from "components/Icons";


function TechChecks({ checks }) {


    const techColumns = useMemo(() => ([
        {
            headerName: 'Alert',
            field: 'alertLevel',
            renderCell: (params) => {
                return (
                    <AlertIcon alert={params.value}></AlertIcon>
                )
            }
        },
        {
            headerName: "Name",
            field: "name",
            width: 200,
            renderCell: (params) => {
                const original = params.row
                return original.url !== "" ? <a href={original.url} target="_blank" rel="noreferrer"> {original.name}</a> : original.name
            },
        },
        {
            width: 100,
            headerName: "Score",
            field: "score",
        },
        {
            headerName: "Details",
            field: "details",
            flex: 1,
            renderCell: (params) => {
                const original = params.row
                let lines = original.details.split(',')
                let linesHTML = lines.map((line) => <div>{line}</div>);
                return linesHTML;
            }
        }

    ]), [])
    return (
        <DataGrid
            getRowId={(row) => uuidv4()}
            rows={checks}
            columns={techColumns}
            canSearch
            autoHeight
            getRowHeight={() => 'auto'}
            entriesPerPage={{ defaultValue: 10 }}
            hideFooter
            showTotalEntries={false} />
    )
}
function Technologies({ project, data, limit }) {
    const techColumns = [
        {
            headerName: "Name",
            field: "name"
        },
        {
            headerName: "Location",
            field: "location",
            flex: 1,
            renderCell: (params) => {
                const original = params.row
                return original.url !== "" ? <Link href={original.url} underline="always" target="_blank" rel="noreferrer"> {original.location}</Link> : original.location
            },


        },
        /*
        {
            headerName: "First Seen",
            field: "firstSeen",
            flex: 1,
            renderCell: (params) => {
                const original = params.row
                return original.firstSeen !== "" ? <Link underline="always" href={`/pages/projects/${project}/builds/${original.firstSeen}`}>{original.firstSeen}</Link> : "-"
            },

        },
        
        {
            headerName: "Score",
            field: "score",
            Cell: ({ row: { original } }) => {

                const location = {
                    pathname: `/pages/projects/${project}/techchecks`,
                }
                return original.score === -1 ? -1 :
                    <Link href={location} state={{ data: original }}>{original.score}</Link>

            },
        },
        */
    ]
    return (
        <DataGrid
            getRowId={(row) => uuidv4()}
            columns={techColumns}
            rows={data}
            hideFooter
            entriesPerPage={{ defaultValue: limit }}
            showTotalEntries={false} />
    )
}

export { Technologies, TechChecks }
export default function testData() {
    return {
        isLoading: false,
        error: false,
        data: {
            "id": "137943b2-4ce2-4556-9c1a-b5c692e14e87",
            "tenantId": "d7098276-4d64-4d49-adfe-1cf4c9e147cc",
            "project": "invisirisk-demo%2Funauthorized-source",
            "builder": "github",
            "BuilderUrl": "https://github.com/",
            "buildUrl": "https://github.com/invisirisk-demo/unauthorized-source/actions/runs/4264292983/attempts/2",
            "status": "Unknown",
            "startTime": "2023-02-24T16:36:43.124Z",
            "endTime": "2023-02-24T16:36:51.174Z",
            "minScore": 0,
            "minScorePkg": "Alert",
            "technologies": [
                {
                    "name": "Builder",
                    "location": "github",
                    "url": "https://github.com/",
                    "firstSeen": "dcc163e5-4ace-46a1-83c8-ceb2df6275d7",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "git",
                    "location": "github.com/invisirisk-demo",
                    "url": "https://github.com/invisirisk-demo",
                    "firstSeen": "dcc163e5-4ace-46a1-83c8-ceb2df6275d7",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "git",
                    "location": "github.com/TheTorProject",
                    "url": "https://github.com/TheTorProject",
                    "firstSeen": "dcc163e5-4ace-46a1-83c8-ceb2df6275d7",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "npm",
                    "location": "registry.npmjs.org",
                    "url": "",
                    "firstSeen": "dcc163e5-4ace-46a1-83c8-ceb2df6275d7",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "npm",
                    "location": "npm.pkg.github.com",
                    "url": "",
                    "firstSeen": "dcc163e5-4ace-46a1-83c8-ceb2df6275d7",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "web",
                    "location": "https://drive.google.com",
                    "url": "https://drive.google.com",
                    "firstSeen": "dcc163e5-4ace-46a1-83c8-ceb2df6275d7",
                    "score": -1,
                    "checks": null
                }
            ],
            "activities": [
                {
                    "name": "git",
                    "action": "pull",
                    "decision": 0,
                    "alertLevel": "",
                    "score": 3.625,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/octet-stream",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum 39ed95b851733ff3aeb39c62c1c66159",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "github.com/invisirisk-demo/unauthorized-source"
                    }
                },
                {
                    "name": "git",
                    "action": "pull",
                    "decision": 2,
                    "alertLevel": "crit",
                    "score": 0,
                    "checks": [
                        {
                            "name": "Alert",
                            "url": "",
                            "alertLevel": "crit",
                            "details": "accessing repo github.com/TheTorProject/gettorbrowser with action pull",
                            "score": 0
                        },
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: text/plain; charset=utf-8",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum dad62404f1b5463c8fea89951c583285",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "github.com/TheTorProject/gettorbrowser"
                    }
                },
                {
                    "name": "git",
                    "action": "pull",
                    "decision": 2,
                    "alertLevel": "crit",
                    "score": 0,
                    "checks": [
                        {
                            "name": "Alert",
                            "url": "",
                            "alertLevel": "crit",
                            "details": "accessing repo github.com/TheTorProject/gettorbrowser with action pull",
                            "score": 0
                        },
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/octet-stream",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum 847e5bd75bca83b1bade62637b16db8b",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "github.com/TheTorProject/gettorbrowser"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "error",
                    "score": 3.875,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum e3145dcd2b26316e4d3b470529587fde",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "color",
                        "version": "4.2.3"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "",
                    "score": 10,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum 63acc5b5c45b136f2377f0c927fa5cfc",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "colorjs",
                        "version": "0.1.9"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "error",
                    "score": 3.625,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum a9411b733475f463a53cdf8656ad0811",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "is-arrayish",
                        "version": "0.3.2"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "error",
                    "score": 4,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum 0248ebc952524207e296a622372faa1f",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "color-convert",
                        "version": "2.0.1"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "error",
                    "score": 3.75,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum a8d4412852471526b8027af2532d0d2b",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "color-name",
                        "version": "1.1.4"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "error",
                    "score": 3.625,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum 40accde4e2a22a6c05b871d0da2e8359",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "simple-swizzle",
                        "version": "0.2.2"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 2,
                    "alertLevel": "warn",
                    "score": 5,
                    "checks": [
                        {
                            "name": "Alert",
                            "url": "",
                            "alertLevel": "warn",
                            "details": "npm access from unknown source",
                            "score": 5
                        },
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: text/html; charset=utf-8",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum a18113db38bc133654ba5fb2a69b6fb9",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "npm.pkg.github.com",
                        "package": "@invisirisk/ir-dep-npm",
                        "version": "1.0.5"
                    }
                },
                {
                    "name": "npm",
                    "action": "get",
                    "decision": 0,
                    "alertLevel": "error",
                    "score": 3.875,
                    "checks": [
                        {
                            "name": "Download-Type",
                            "url": "",
                            "alertLevel": "",
                            "details": "mime: application/gzip",
                            "score": 10
                        },
                        {
                            "name": "Download-Checksum",
                            "url": "",
                            "alertLevel": "",
                            "details": "checksum 0ca6a6c76fa119f0b80d60a9ab286db4",
                            "score": 10
                        }
                    ],
                    "details": {
                        "repo": "registry.npmjs.org",
                        "package": "color-string",
                        "version": "1.9.1"
                    }
                },
                {
                    "name": "web",
                    "action": "GET",
                    "decision": 1,
                    "alertLevel": "crit",
                    "score": 0,
                    "checks": [
                        {
                            "name": "Blocked",
                            "url": "",
                            "alertLevel": "crit",
                            "details": "Blocked by policy",
                            "score": 0
                        }
                    ],
                    "details": {
                        "url": "https://drive.google.com/uc?export=download&id=1tzTSWJ54w2IjpUjCSnGQqj8ZXhblWEwe"
                    }
                }
            ],
            "scm": "git",
            "scmOrigin": "https://github.com/invisirisk-demo/unauthorized-source",
            "scmCommit": "7bcdb78fe19a87eadf5596284252842487308041",
            "scmPrevCommit": "",
            "scmBranch": "main",
            "alerts": [
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "repo",
                    "target": "color",
                    "ecosystem": "npm",
                    "details": "branch protection not enabled on development/release branches"
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "repo",
                    "target": "is-arrayish",
                    "ecosystem": "npm",
                    "details": "branch protection not enabled on development/release branches"
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "repo",
                    "target": "color-convert",
                    "ecosystem": "npm",
                    "details": "branch protection not enabled on development/release branches"
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "repo",
                    "target": "color-name",
                    "ecosystem": "npm",
                    "details": "branch protection not enabled on development/release branches"
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "repo",
                    "target": "simple-swizzle",
                    "ecosystem": "npm",
                    "details": "branch protection not enabled on development/release branches"
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "repo",
                    "target": "color-string",
                    "ecosystem": "npm",
                    "details": "branch protection not enabled on development/release branches"
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "crit",
                    "targetType": "build",
                    "target": "Alert",
                    "details": "Activity \"git\" resulted in accessing repo github.com/TheTorProject/gettorbrowser with action pull",
                    "decision": 2
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "crit",
                    "targetType": "build",
                    "target": "Alert",
                    "details": "Activity \"git\" resulted in accessing repo github.com/TheTorProject/gettorbrowser with action pull",
                    "decision": 2
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "warn",
                    "targetType": "build",
                    "target": "Alert",
                    "details": "Activity \"npm\" resulted in npm access from unknown source",
                    "decision": 2
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "crit",
                    "targetType": "build",
                    "target": "Blocked",
                    "details": "Activity \"web\" resulted in Blocked by policy",
                    "decision": 1
                }
            ]
        }
    }
}
export function testData() {
    return {
        isLoading: false,
        error: false,
        data: {
            "repo": {
                "name": "",
                "commit": ""
            },
            "scorecard": {
                "version": "",
                "commit": ""
            },
            "languages": null,
            "score": 4.800000190734863,
            "checks": [
                {
                    "score": 10,
                    "details": null,
                    "reason": "no binaries found in the repo",
                    "name": "Binary-Artifacts",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 0,
                    "details": [
                        "Warn: branch protection not enabled for branch 'patch-0.6.1'"
                    ],
                    "reason": "branch protection not enabled on development/release branches",
                    "name": "Branch-Protection",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 0,
                    "details": null,
                    "reason": "0 out of 6 merged PRs checked by a CI test -- score normalized to 0",
                    "name": "CI-Tests",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 2,
                    "details": [
                        "Warn: no reviews found for commit: 04907d5b2dc88bef63e5399452fa6a696f90bf66",
                        "Warn: no reviews found for commit: 7ea048db19186e0c7da8bf2354bca73ace5adc1a",
                        "Warn: no reviews found for commit: ed384350b16ce64606898500861f903856684e9b",
                        "Warn: no reviews found for commit: 47654d26c5bbca04b9fb5008a550ed4529c2e94e",
                        "Warn: no reviews found for commit: b4a7b6d5c00859e5a47ad3b6f3475808152559c0",
                        "Warn: no reviews found for commit: 895d9cc038968d3eef5edd63140db847d196663a",
                        "Warn: no reviews found for commit: 0903e196ee5c6c05e9ba13df134cfa9a3e8a43e3",
                        "Warn: no reviews found for commit: d650556c120577339db368944011066b596a71df",
                        "Warn: no reviews found for commit: a14498cda8215caa8473f25b5ffba774ffc5da66",
                        "Warn: no reviews found for commit: 47c54f411f5472099fd928b13dd1c7a4144fe4f0",
                        "Warn: no reviews found for commit: bbabe9a02e3c87427d69290de10d894cb9aca4af",
                        "Warn: no reviews found for commit: f97b3ff479a0b0dd66eaeeef5775f1466e907e66",
                        "Warn: no reviews found for commit: 7ead0ec998b3a92155193abb0a5f667eaf4e6b20",
                        "Warn: no reviews found for commit: e7bff2c9eb374f4b6abdb4e09c1dc20617c8ef7f",
                        "Warn: no reviews found for commit: 930f6979f28442acef60820ab14195371c929d20",
                        "Warn: no reviews found for commit: 0de91cd5d25f2da3e24b41d99448846239f5f13f",
                        "Warn: no reviews found for commit: aa44c5efe39ee42b97f3baa10e50cf2202ebcaef",
                        "Warn: no reviews found for commit: d95423f77edef6cbb9e21d2d6014c7de85ae220a",
                        "Warn: no reviews found for commit: 829bc1fc3a9a62c1f68a4d725d06e7b2c9142835",
                        "Warn: no reviews found for commit: ac518d2f21818146f3310557bd51c13d8cff2ba8",
                        "Warn: no reviews found for commit: 5d4678c0ace727d467c6fdf33af090cf64a8123a",
                        "Warn: no reviews found for commit: 1d77c0b4e144dd5df88a339e5f91eb79c2bb37db",
                        "Warn: no reviews found for commit: 2c6fb7e30bae18d7213a721c2854cb24a84cab04",
                        "Warn: no reviews found for commit: e34d6aa0036b51d1b00bda99ec7710f204a2dc5b"
                    ],
                    "reason": "GitHub code reviews found for 6 commits out of the last 30 -- score normalized to 2",
                    "name": "Code-Review",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 10,
                    "details": [
                        "Info: contributors work for KosyanMedia,NUDelta,OS-Maintainer-Feedback-Group,Scoopit,atom,babel,boulder-emacs,browserify,bytecodealliance,css-modules,easyant,elm-tooling,facebook,gimli-rs,goose-duck,insurello,jmpressjs,kosyanmedia,libffi,maintainers,mozilla,open-bot,openjs-foundation,papers-we-love,pdxrust,pnpm,prettier,rust-fuzz,rust-lang,rustwasm,sasstools,servo,sproutsocial,vercel,webpack,webpack-contrib,yarnpkg"
                    ],
                    "reason": "37 different organizations found -- score normalized to 10",
                    "name": "Contributors",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 10,
                    "details": null,
                    "reason": "no dangerous workflow patterns detected",
                    "name": "Dangerous-Workflow",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 0,
                    "details": [
                        "Warn: dependabot config file not detected in source location.\n\t\t\tWe recommend setting this configuration in code so it can be easily verified by others.",
                        "Warn: renovatebot config file not detected in source location.\n\t\t\tWe recommend setting this configuration in code so it can be easily verified by others."
                    ],
                    "reason": "no update tool detected",
                    "name": "Dependency-Update-Tool",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 0,
                    "details": null,
                    "reason": "project is not fuzzed",
                    "name": "Fuzzing",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 0,
                    "details": null,
                    "reason": "0 commit(s) out of 30 and 0 issue activity out of 6 found in the last 90 days -- score normalized to 0",
                    "name": "Maintained",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": -1,
                    "details": [
                        "Warn: no GitHub publishing workflow detected"
                    ],
                    "reason": "no published package detected",
                    "name": "Packaging",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 10,
                    "details": [
                        "Info: GitHub-owned GitHubActions are pinned",
                        "Info: Third-party GitHubActions are pinned",
                        "Info: Dockerfile dependencies are pinned",
                        "Info: no insecure (not pinned by hash) dependency downloads found in Dockerfiles",
                        "Info: no insecure (not pinned by hash) dependency downloads found in shell scripts"
                    ],
                    "reason": "all dependencies are pinned",
                    "name": "Pinned-Dependencies",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 0,
                    "details": [
                        "Warn: 0 commits out of 6 are checked with a SAST tool",
                        "Warn: CodeQL tool not detected"
                    ],
                    "reason": "SAST tool is not run on all commits -- score normalized to 0",
                    "name": "SAST",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": -1,
                    "details": [
                        "Warn: no GitHub releases found"
                    ],
                    "reason": "no releases found",
                    "name": "Signed-Releases",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 10,
                    "details": null,
                    "reason": "tokens are read-only in GitHub workflows",
                    "name": "Token-Permissions",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                },
                {
                    "score": 10,
                    "details": null,
                    "reason": "no vulnerabilities detected",
                    "name": "Vulnerabilities",
                    "priority": "",
                    "Documentation": {
                        "URL": "",
                        "Short": ""
                    }
                }
            ],
            "checksv2": null
        }
    }
}
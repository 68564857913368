import { configureStore } from '@reduxjs/toolkit'
import { invisiriskApi } from '../services/invisirisk_api'

export const store = configureStore({
    reducer: {

        [invisiriskApi.reducerPath]: invisiriskApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(invisiriskApi.middleware),

})

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

import {
    Legend,
    Chart as ChartJS,
} from 'chart.js'
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    Legend
);

export function BarChart({ buckets, industry }) {
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: buckets.name
            }
        },
        scales: {
            y: {
                title: { display: true, text: '% of Packages' }
            },
            x: {
                title: { display: true, text: 'Score range' }
            }
        }

    };
    const labels = buckets.bucket.map(b => (b.min ? b.min : 0));
    const initialValue = 0;
    const bucketTotal = buckets.bucket.reduce(
        (currentValue, b) => {
            return (b.count ? b.count : 0) + currentValue
        },
        initialValue
    );

    const avgBucketTotal = industry.reduce(

        (currentValue, b) => {
            return (b.count ? b.count : 0) + currentValue
        },
        initialValue
    );
    const data = {
        labels,
        datasets: [
            {
                label: 'Dependency ',
                data: buckets.bucket.map(b => (b.count ? b.count : 0) * 100 / bucketTotal),
                backgroundColor: 'rgba(0, 68, 38, 0.3)',

            },
            {
                label: 'Industry',
                data: industry.map(b => (b.count ? b.count : 0) * 100 / avgBucketTotal),
                backgroundColor: 'rgba(68, 00,31, 0.3)',


            }
        ],
    };
    return (
        <Card>
            <Box m={2}>
                <Bar data={data} options={options} />
            </Box>
        </Card>
    )
}

function avgBucket(industry, name) {
    return industry.find(d => d.name === name).bucket
}

export function ScoreCharts({ scores, industry }) {
    return (
        scores.map(b => {
            return (
                <Grid item xs={12}>
                    <BarChart buckets={b} industry={avgBucket(industry, b.name)}></BarChart>
                </Grid>
            )
        })

    )
}



import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';

import Footer from "examples/Footer";
import IrDahshboardLayout from "components/IrDashboard";
import { useGetRepoQuery } from 'services/invisirisk_api';
import { Typography } from "@mui/material";

function LinkButton({ repo }) {
    return (
        <Link underline='always' href={'/pages/Repositories/' + repo} > {repo}</Link >
    )
}
export default function RepoList() {

    const testMode = process.env.REACT_APP_TEST ? true : false;

    let { data, error, isLoading } = useGetRepoQuery();
    if (testMode) {
        data = [
            {
                "providerName": "github",
                "repoPath": "github.com/usirsiwal/testrepo",
                "analytics": null
            },
            {
                "providerName": "github",
                "repoPath": "github.com/usirsiwal/godep",
                "analytics": null
            }
        ]
        isLoading = false;
        error = false;
    }
    if (isLoading || error) {
        return (
            <IrDahshboardLayout>
                <Box pt={0.25} pb={1} >
                    <Typography>Loading...</Typography>
                </Box>
                <Footer />
            </IrDahshboardLayout>
        )
    }
    if (!data) {
        return (
            <IrDahshboardLayout>
                <Box pt={0.25} pb={1} >
                    <Typography>Please share repositories with me and relogin</Typography>
                </Box>
                <Footer />
            </IrDahshboardLayout>
        )
    }
    const columns = [

        {
            field: 'repoPath',
            headerName: 'Repositories',
            sortable: false,
            width: 1000,

            renderCell: (d) => {
                return (
                    <LinkButton repo={d.value} />
                )
            },

        },
    ];

    return (
        <IrDahshboardLayout>
            <Box pt={0.25} pb={1} >
                <Box p={3} lineHeight={1}>
                    <Typography variant="h5" fontWeight="medium">
                        Your Repositories
                    </Typography>
                    <Typography variant="button" color="text">
                        Repositories we know about.
                    </Typography>
                </Box>
                <Card m={2}>

                    <Box style={{ display: 'flex', height: '100vh' }}>
                        <Box style={{ flexGrow: 1 }}>
                            <DataGrid
                                getRowId={(row) => row.repoPath}
                                rows={data}
                                options={{
                                    customHeadRender: () => null
                                }}
                                columns={columns}
                            />
                        </Box>
                    </Box>
                </Card>
            </Box>
            <Footer />
        </IrDahshboardLayout >

    );
}

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Dashboard 2 PRO React examples

import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';

import BuildInfoCard from "./info-card";

import { useMemo } from 'react'
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Technologies } from "examples/Technologies";
import { Icon, Typography } from "@mui/material"
import { DiGit, DiNpm, DiGo } from "react-icons/di";
import { MdHttp, MdGetApp } from "react-icons/md";
import { CgPushUp } from "react-icons/cg";
import { useGetBuildQuery, useGetRepoQuery } from "services/invisirisk_api";
import { v4 as uuidv4 } from 'uuid';
import testData from "./test-data";
import Tooltip from '@mui/material/Tooltip';

import Alerts from "components/Alerts";
import { AlertIcon } from "components/Icons";

import IrDahshboardLayout from "components/IrDashboard";





function sortData(data) {
  const alertLevel = {
    'crit': -300,
    'error': -200,
    'warn': -100,
  }
  let dataCopy = data.slice();
  dataCopy.sort((a, b) => {
    let al = -1;
    if (a.alertLevel in alertLevel) {
      al = alertLevel[a.alertLevel]
    } else {
      al = a.score === -1 ? 1000 : a.score;

    }
    let bl = -1;
    if (b.alertLevel in alertLevel) {
      bl = alertLevel[b.alertLevel]
    } else {
      bl = b.score === -1 ? 1000 : b.score;
    }

    return al - bl;
  })
  return dataCopy
}

function scorePath(act) {
  switch (act.name) {

    case 'maven':
    case 'npm':
    case 'gomodule':
      return "score/" + act.name + "/" + act.details.version + "/" + act.details.package
    case 'git':
      return '/pages/Repositories/' + act.details.repo

    default:
      return ""
  }

}

function actionIcon(name) {
  const style = { color: "black", fontSize: "2em", size: 25 }
  switch (name) {
    case "get":
    case "pull":
    case "GET":
      return <MdGetApp style={style} />
    case "POST":
      return <CgPushUp style={style} />

    default:
      return name

  }
}

function activityIcon(name) {
  const style = { color: "black", fontSize: "2em", size: 25 }
  switch (name) {
    case "git":
      return <DiGit style={style}></DiGit>
    case "npm":
      return <DiNpm style={style} />
    case "gomodule":
      return <DiGo style={style} />
    case "web":
      return <MdHttp style={style} />
    default:
      return name

  }
}

function repoFind(repos, repo) {
  if (!repo) return false;
  return repos.find((r) => r.repoPath === repo) !== undefined
}
function BuildTable() {

  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/')
  // /pages/projects/invisirisk%2Fdemo/builds/0f949c45-1eaf-41e6-9d92-d81d8774830b
  const buildId = parts[5]
  const projectName = parts[3]
  let repos = useGetRepoQuery();


  const columns = useMemo(() => ([
    {
      headerName: "Alert",
      field: "alertLevel",
      width: 100,

      renderCell: (d) => {
        const original = d.row;
        return <AlertIcon alert={original.alertLevel} />
      },
    },
    {
      headerName: "Name",
      field: "details.package",
      width: 500,
      renderCell: (d) => {
        const original = d.row;
        switch (original.name) {
          case 'git':
            return original.details.repo
          case 'maven':
          case 'npm':
          case 'gomodule':
            return original.details.repo + "/" + original.details.package + "@" + original.details.version
          case 'web':
            return <div title={original.details.url}>{original.details.url.slice(0, 50)}</div>
          default:
            return null
        }


      },
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (d) => {
        const original = d.row;
        const act = actionIcon(original.action)
        return < Tooltip title={original.action} position="right">
          {act}
        </Tooltip >

      }
    },
    {
      headerName: "Type",
      field: "name",
      flex: 1,

      renderCell: (d) => {
        const original = d.row;
        const act = activityIcon(original.name)
        return < Tooltip title={original.name} position="right">
          {act}
        </Tooltip >
      }

    },
    {
      headerName: "Decision",
      field: "decision",
      flex: 1,

      renderCell: (d) => {
        const original = d.row;
        return original.decision !== 1 ? <Icon fontSize="2em" color="success">done</Icon> : <Icon fontSize="2em" color="error">block</Icon>
      }
    },

    {
      headerName: "Score",
      field: "score",
      flex: 1,

      renderCell: (d) => {
        const original = d.row;
        if (original.name === "web" || (original.name === "git" && !repoFind(repos.data, original.details.repo))) {
          const location = {
            pathname: `/pages/projects/${original.name}/techchecks`,
          };
          return original.score === -1 ? -1 :
            <Link to={location} state={{ data: original }}>{original.score.toFixed(2)}</Link>;
        } else {
          return original.score !== -1 ? <Link to={`${scorePath(original)}`} state={{ data: original }}>{original.score.toFixed(2)}</Link> : -1
        }
      }


    },
  ]), [repos])



  let response = useGetBuildQuery({ project: projectName, build: buildId })
  const testMode = process.env.REACT_APP_TEST ? true : false;
  if (testMode) {
    response = testData()
    repos = {
      isLoading: false,
      error: null,
      data: [
        {
          "providerName": "github",
          "repoPath": "github.com/invisirisk-demo/secret-leak",
          "dependencies": null,
          "selfScore": null,
          "alerts": null
        },
        {
          "providerName": "github",
          "repoPath": "github.com/invisirisk-demo/unauthorized-source",
          "dependencies": null,
          "selfScore": null,
          "alerts": null
        }
      ]
    }
  }

  if (response.isLoading || response.error || repos.isLoading || repos.error) {
    return <div>Loading...</div>
  }


  let act = response.data.activities
  if (act == null) {
    act = []
  }

  let sortedActivities = sortData(act);
  let techs = response.data.technologies
  if (techs == null) {
    techs = []
  }



  const buildInfo = (build) => {
    let originhref
    if (build.scmOrigin && build.scmBranch) {
      originhref = build.scmOrigin + "/tree/" + build.scmBranch
    }
    const getOrigin = () => (

      (build.scmOrigin && build.scmOrigin.startsWith("https://github.com/")) ?
        <a href={originhref} target={"_blank"} rel={"noreferrer"}>{build.scmOrigin}</a>
        : build.scmOrigin
    )

    return {
      Builder: build.builder,
      "Build URL": <a href={build.buildUrl} target="_blank" rel="noreferrer"> {build.buildUrl}</a>,
      "Start Time": build.startTime,
      "End Time": build.endTime,
      "Source": getOrigin(),

    }
  }
  return (
    <IrDahshboardLayout>
      <Box pt={0.25} pb={1}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={6}>
                <BuildInfoCard
                  info={buildInfo(response.data)}
                />
              </Grid>
              <Grid item xs={6}>
                <Technologies project={projectName} data={techs} limit={4} />
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Typography variant="h6" component="h2" gutterBottom>Activities</Typography>
                </Card>
                <Card>
                  <Box >
                    <Box>
                      <DataGrid columns={columns}
                        autoHeight
                        compact
                        rowsPerPageOptions={[5, 10, 20]}
                        rows={sortedActivities}
                        getRowId={(row) => uuidv4()}
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Alerts alerts={response.data.alerts}></Alerts>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </IrDahshboardLayout>
  );
}

export default BuildTable;

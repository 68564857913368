export default function test_data() {
    return {
        isLoading: false,
        error: null,
        data: {
            "project": "secret-leak-build",
            "buildCount": 14,
            "builds": [
                {
                    "id": "cf25ddd8-1b95-45eb-b49b-1ba1e44228d9",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3640135314",
                    "status": "Success",
                    "startTime": "2023-01-21T16:59:54.999Z",
                    "endTime": "2023-01-21T17:00:12.675Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "cde1739d-6fce-4f23-8be1-a2e8cc0c2876",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 10,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "error",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value 64_WXXXXXXXXXXXXXXXXXXP,secret value 64_WXXXXXXXXXXXXXXXXXXP,secret value cim5XXXXXXXXXXXXXXXj,secret value 64_WXXXXXXXXXXXXXXXXXXP,secret value 64_WXXXXXXXXXXXXXXXXXXP,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 7511f9f935be974d0207091755bb2d6a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3433525581",
                    "status": "Success",
                    "startTime": "2022-12-06T19:09:41.252Z",
                    "endTime": "2022-12-06T19:09:57.268Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "c223c3df-161c-41c9-ae35-9fac96fe46d5",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "c223c3df-161c-41c9-ae35-9fac96fe46d5",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "c223c3df-161c-41c9-ae35-9fac96fe46d5",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 10,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "error",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value xZhDX-XXXXXXXXXXXXX3,secret value xZhDX-XXXXXXXXXXXXX3,secret value cim5XXXXXXXXXXXXXXXj,secret value xZhDX-XXXXXXXXXXXXX3,secret value xZhDX-XXXXXXXXXXXXX3,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum a90ec8df2f1ec56af2b5ad590987f81c",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "c223c3df-161c-41c9-ae35-9fac96fe46d5",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3433451419",
                    "status": "Success",
                    "startTime": "2022-12-06T18:57:56.084Z",
                    "endTime": "2022-12-06T18:58:17.803Z",
                    "minScore": 0,
                    "minScorePkg": "GitHub-Personal-Access-Token",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "c9416689-355f-46e5-acdc-2ed9b61b4ab7",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "3dc42aee-1818-4a8a-b290-1b111d721e72",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "3dc42aee-1818-4a8a-b290-1b111d721e72",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "error",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value 8xtzXXXXXXXXXXXXXXXp,secret value 8xtzXXXXXXXXXXXXXXXp,secret value cim5XXXXXXXXXXXXXXXj,secret value 8xtzXXXXXXXXXXXXXXXp,secret value 8xtzXXXXXXXXXXXXXXXp,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum d8fea3fb62e843580663fc2ffa03f8e7",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "3dc42aee-1818-4a8a-b290-1b111d721e72",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3405470705",
                    "status": "Success",
                    "startTime": "2022-12-01T00:04:13.143Z",
                    "endTime": "2022-12-01T00:04:29.733Z",
                    "minScore": 0,
                    "minScorePkg": "GitHub-Personal-Access-Token",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "f4038f99-90e8-4b88-a219-f3c9ea72c105",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "f4038f99-90e8-4b88-a219-f3c9ea72c105",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "f4038f99-90e8-4b88-a219-f3c9ea72c105",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 2,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "error",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value PAqbXXXX-XXXXXXXXXXe,secret value PAqbXXXX-XXXXXXXXXXe,secret value cim5XXXXXXXXXXXXXXXj,secret value PAqbXXXX-XXXXXXXXXXe,secret value PAqbXXXX-XXXXXXXXXXe,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "f4038f99-90e8-4b88-a219-f3c9ea72c105",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3405449443",
                    "status": "Success",
                    "startTime": "2022-12-01T00:01:12.758Z",
                    "endTime": "2022-12-01T00:01:29.723Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "dd099522-02b7-42ca-9d3f-ff88341b5ac8",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "dd099522-02b7-42ca-9d3f-ff88341b5ac8",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "dd099522-02b7-42ca-9d3f-ff88341b5ac8",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "error",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value njXKXXXXXXXXXXXXXXXX,secret value njXKXXXXXXXXXXXXXXXX,secret value cim5XXXXXXXXXXXXXXXj,secret value njXKXXXXXXXXXXXXXXXX,secret value njXKXXXXXXXXXXXXXXXX,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "dd099522-02b7-42ca-9d3f-ff88341b5ac8",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3405226625",
                    "status": "Success",
                    "startTime": "2022-11-30T22:39:35.121Z",
                    "endTime": "2022-11-30T22:39:49.639Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "4635c6ae-3818-4ffd-a2ac-1326aa810391",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "4635c6ae-3818-4ffd-a2ac-1326aa810391",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "4635c6ae-3818-4ffd-a2ac-1326aa810391",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "warn",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value dkdWXXXXXXXXXXXXXXXU,secret value dkdWXXXXXXXXXXXXXXXU,secret value cim5XXXXXXXXXXXXXXXj,secret value dkdWXXXXXXXXXXXXXXXU,secret value dkdWXXXXXXXXXXXXXXXU,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "4635c6ae-3818-4ffd-a2ac-1326aa810391",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3405214978",
                    "status": "Success",
                    "startTime": "2022-11-30T22:36:38.471Z",
                    "endTime": "2022-11-30T22:36:53.343Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "e99d552c-16ac-4576-a6dc-d6bc8c3de974",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "e99d552c-16ac-4576-a6dc-d6bc8c3de974",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "e99d552c-16ac-4576-a6dc-d6bc8c3de974",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value 4ciZXXXXXXXXXXXXXXXT,secret value 4ciZXXXXXXXXXXXXXXXT,secret value cim5XXXXXXXXXXXXXXXj,secret value 4ciZXXXXXXXXXXXXXXXT,secret value 4ciZXXXXXXXXXXXXXXXT,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "e99d552c-16ac-4576-a6dc-d6bc8c3de974",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3405205169",
                    "status": "Success",
                    "startTime": "2022-11-30T22:31:56.04Z",
                    "endTime": "2022-11-30T22:32:13.519Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "3433197e-25e8-42fb-a185-20be7be99eeb",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "3433197e-25e8-42fb-a185-20be7be99eeb",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "3433197e-25e8-42fb-a185-20be7be99eeb",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value KsrUXX-XXXXXXXXXXXXj,secret value KsrUXX-XXXXXXXXXXXXj,secret value cim5XXXXXXXXXXXXXXXj,secret value KsrUXX-XXXXXXXXXXXXj,secret value KsrUXX-XXXXXXXXXXXXj,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "3433197e-25e8-42fb-a185-20be7be99eeb",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3405052652",
                    "status": "Success",
                    "startTime": "2022-11-30T22:01:20.885Z",
                    "endTime": "2022-11-30T22:01:38.356Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "b49f174e-ec5f-4120-a372-e6ebe2cc6019",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "b49f174e-ec5f-4120-a372-e6ebe2cc6019",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "b49f174e-ec5f-4120-a372-e6ebe2cc6019",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value c-8LXXXX-XXXXXXXX-Xg,secret value c-8LXXXX-XXXXXXXX-Xg,secret value cim5XXXXXXXXXXXXXXXj,secret value c-8LXXXX-XXXXXXXX-Xg,secret value c-8LXXXX-XXXXXXXX-Xg,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "error",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "b49f174e-ec5f-4120-a372-e6ebe2cc6019",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3404920022",
                    "status": "Success",
                    "startTime": "2022-11-30T21:32:15.93Z",
                    "endTime": "2022-11-30T21:32:33.182Z",
                    "minScore": 0,
                    "minScorePkg": "Generic-API-Key",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "f8aea6d7-0550-4d88-83d4-b4fb192b445e",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "f8aea6d7-0550-4d88-83d4-b4fb192b445e",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "f8aea6d7-0550-4d88-83d4-b4fb192b445e",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 2,
                            "alertLevel": "",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "secret value 9PQJXXXXXXXXXXXXXXXD,secret value 9PQJXXXXXXXXXXXXXXXD,secret value cim5XXXXXXXXXXXXXXXj,secret value 9PQJXXXXXXXXXXXXXXXD,secret value 9PQJXXXXXXXXXXXXXXXD,",
                                    "score": 0
                                },
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum aa7592adcf872091f9c84fd86f774c9a",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "f8aea6d7-0550-4d88-83d4-b4fb192b445e",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3404277298",
                    "status": "Success",
                    "startTime": "2022-11-30T18:52:10.155Z",
                    "endTime": "2022-11-30T18:52:35.897Z",
                    "minScore": 3.5,
                    "minScorePkg": "github.com/hirochachacha/go-smb2",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "eea51daa-3cbb-424f-8c24-ec33446becc8",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "09f3e3a4-bd8d-4c5a-a9a2-1755d20c9118",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "09f3e3a4-bd8d-4c5a-a9a2-1755d20c9118",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 1,
                            "alertLevel": "",
                            "score": -1,
                            "checks": null,
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "09f3e3a4-bd8d-4c5a-a9a2-1755d20c9118",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3383574309",
                    "status": "Success",
                    "startTime": "2022-11-26T17:28:56.744Z",
                    "endTime": "2022-11-26T17:29:11.961Z",
                    "minScore": 3.5,
                    "minScorePkg": "github.com/hirochachacha/go-smb2",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "dc0805dd-0982-4356-83ea-c39473dc2e4d",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "d1fc8087-5d91-4d9f-af22-d81677437cfe",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "d1fc8087-5d91-4d9f-af22-d81677437cfe",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 5.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 1,
                            "alertLevel": "",
                            "score": -1,
                            "checks": null,
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "d1fc8087-5d91-4d9f-af22-d81677437cfe",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3328765270",
                    "status": "Success",
                    "startTime": "2022-11-15T22:51:23.948Z",
                    "endTime": "2022-11-15T22:51:42.466Z",
                    "minScore": 3.5,
                    "minScorePkg": "github.com/hirochachacha/go-smb2",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "dbd1e843-962b-4775-989b-12f2a09b4896",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "dbd1e843-962b-4775-989b-12f2a09b4896",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "dbd1e843-962b-4775-989b-12f2a09b4896",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 6.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 1,
                            "alertLevel": "",
                            "score": -1,
                            "checks": null,
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                },
                {
                    "id": "dbd1e843-962b-4775-989b-12f2a09b4896",
                    "project": "secret-leak-build",
                    "builder": "gitlab",
                    "BuilderUrl": "https://gitlab.com",
                    "buildUrl": "https://gitlab.com/invisirisk/secret-leak/-/jobs/3328757804",
                    "status": "Success",
                    "startTime": "2022-11-15T22:48:17.67Z",
                    "endTime": "2022-11-15T22:48:41.621Z",
                    "minScore": 0,
                    "minScorePkg": "GitHub-Personal-Access-Token",
                    "technologies": [
                        {
                            "name": "Builder",
                            "location": "gitlab",
                            "url": "https://gitlab.com",
                            "firstSeen": "63e0b9a2-d5dd-4287-9d98-a57a657fe912",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "gomodule",
                            "location": "proxy.golang.org",
                            "url": "",
                            "firstSeen": "",
                            "score": -1,
                            "checks": null
                        },
                        {
                            "name": "web",
                            "location": "https://app.a.invisirisk.com",
                            "url": "https://app.a.invisirisk.com",
                            "firstSeen": "",
                            "score": -1,
                            "checks": null
                        }
                    ],
                    "activities": [
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.5,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 34c1b3a4a6bc7d715dfbb6c84e60cdf5",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/hirochachacha/go-smb2",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 7.599999904632568,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 2b9fbc411905063a46c5e2b90798b333",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/cobra",
                                "version": "v1.6.1"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 6.300000190734863,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum bd62aa68dc876e4f779d307522fc3049",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/spf13/pflag",
                                "version": "v1.0.5"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": -1,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 90b8f50c00792e2f76643f304c271602",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "golang.org/x/crypto",
                                "version": "v0.0.0-20200728195943-123391ffb6de"
                            }
                        },
                        {
                            "name": "gomodule",
                            "action": "get",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 3.700000047683716,
                            "checks": [
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: application/zip",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum 45998db56227ad3b675a904508401140",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "repo": "proxy.golang.org",
                                "package": "github.com/geoffgarside/ber",
                                "version": "v1.1.0"
                            }
                        },
                        {
                            "name": "web",
                            "action": "POST",
                            "decision": 0,
                            "alertLevel": "",
                            "score": 0,
                            "checks": [
                                {
                                    "name": "GitHub-Personal-Access-Token",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                                    "score": 0
                                },
                                {
                                    "name": "Generic-API-Key",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "secret value r8t5XXXXXXXXXXXXXXXa,secret value r8t5XXXXXXXXXXXXXXXa,secret value cim5XXXXXXXXXXXXXXXj,secret value r8t5XXXXXXXXXXXXXXXa,secret value r8t5XXXXXXXXXXXXXXXa,",
                                    "score": 0
                                },
                                {
                                    "name": "Download-Type",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "mime: text/html; charset=utf-8",
                                    "score": 10
                                },
                                {
                                    "name": "Download-Checksum",
                                    "url": "",
                                    "alertLevel": "",
                                    "details": "checksum cba02f790046badf206a8452b7600b64",
                                    "score": 10
                                }
                            ],
                            "details": {
                                "url": "https://app.a.invisirisk.com/"
                            }
                        }
                    ],
                    "scm": "git",
                    "scmOrigin": "https://gitlab.com",
                    "scmCommit": "main",
                    "scmPrevCommit": "0000000000000000000000000000000000000000",
                    "scmBranch": "main"
                }
            ],
            "technologies": [
                {
                    "name": "Builder",
                    "location": "gitlab",
                    "url": "https://gitlab.com",
                    "firstSeen": "cde1739d-6fce-4f23-8be1-a2e8cc0c2876",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "gomodule",
                    "location": "proxy.golang.org",
                    "url": "",
                    "firstSeen": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                    "score": -1,
                    "checks": null
                },
                {
                    "name": "web",
                    "location": "https://app.a.invisirisk.com",
                    "url": "https://app.a.invisirisk.com",
                    "firstSeen": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                    "score": -1,
                    "checks": null
                }
            ],
            "scoreHistory": [
                {
                    "buildId": "dbd1e843-962b-4775-989b-12f2a09b4896",
                    "score": 0
                },
                {
                    "buildId": "d1fc8087-5d91-4d9f-af22-d81677437cfe",
                    "score": 3.5
                },
                {
                    "buildId": "09f3e3a4-bd8d-4c5a-a9a2-1755d20c9118",
                    "score": 3.5
                },
                {
                    "buildId": "f8aea6d7-0550-4d88-83d4-b4fb192b445e",
                    "score": 3.5
                },
                {
                    "buildId": "b49f174e-ec5f-4120-a372-e6ebe2cc6019",
                    "score": 0
                },
                {
                    "buildId": "3433197e-25e8-42fb-a185-20be7be99eeb",
                    "score": 0
                },
                {
                    "buildId": "e99d552c-16ac-4576-a6dc-d6bc8c3de974",
                    "score": 0
                },
                {
                    "buildId": "4635c6ae-3818-4ffd-a2ac-1326aa810391",
                    "score": 0
                },
                {
                    "buildId": "dd099522-02b7-42ca-9d3f-ff88341b5ac8",
                    "score": 0
                },
                {
                    "buildId": "f4038f99-90e8-4b88-a219-f3c9ea72c105",
                    "score": 0
                },
                {
                    "buildId": "3dc42aee-1818-4a8a-b290-1b111d721e72",
                    "score": 0
                },
                {
                    "buildId": "c223c3df-161c-41c9-ae35-9fac96fe46d5",
                    "score": 0
                },
                {
                    "buildId": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                    "score": 0
                },
                {
                    "buildId": "cf25ddd8-1b95-45eb-b49b-1ba1e44228d9",
                    "score": 0
                }
            ],
            "activityHistory": [
                {
                    "buildId": "dbd1e843-962b-4775-989b-12f2a09b4896",
                    "activityCount": 6
                },
                {
                    "buildId": "d1fc8087-5d91-4d9f-af22-d81677437cfe",
                    "activityCount": 6
                },
                {
                    "buildId": "09f3e3a4-bd8d-4c5a-a9a2-1755d20c9118",
                    "activityCount": 6
                },
                {
                    "buildId": "f8aea6d7-0550-4d88-83d4-b4fb192b445e",
                    "activityCount": 6
                },
                {
                    "buildId": "b49f174e-ec5f-4120-a372-e6ebe2cc6019",
                    "activityCount": 6
                },
                {
                    "buildId": "3433197e-25e8-42fb-a185-20be7be99eeb",
                    "activityCount": 6
                },
                {
                    "buildId": "e99d552c-16ac-4576-a6dc-d6bc8c3de974",
                    "activityCount": 6
                },
                {
                    "buildId": "4635c6ae-3818-4ffd-a2ac-1326aa810391",
                    "activityCount": 6
                },
                {
                    "buildId": "dd099522-02b7-42ca-9d3f-ff88341b5ac8",
                    "activityCount": 6
                },
                {
                    "buildId": "f4038f99-90e8-4b88-a219-f3c9ea72c105",
                    "activityCount": 6
                },
                {
                    "buildId": "3dc42aee-1818-4a8a-b290-1b111d721e72",
                    "activityCount": 6
                },
                {
                    "buildId": "c223c3df-161c-41c9-ae35-9fac96fe46d5",
                    "activityCount": 6
                },
                {
                    "buildId": "6a72e062-9c40-4c9b-ada0-59bb8193cb0c",
                    "activityCount": 6
                },
                {
                    "buildId": "cf25ddd8-1b95-45eb-b49b-1ba1e44228d9",
                    "activityCount": 6
                }
            ],
            "techScoreHistory": [],
            "alerts": [
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "build",
                    "details": "secret value 64_WXXXXXXXXXXXXXXXXXXP,secret value 64_WXXXXXXXXXXXXXXXXXXP,secret value cim5XXXXXXXXXXXXXXXj,secret value 64_WXXXXXXXXXXXXXXXXXXP,secret value 64_WXXXXXXXXXXXXXXXXXXP,",
                    "decision": 2
                },
                {
                    "created": "0001-01-01T00:00:00Z",
                    "cleared": "0001-01-01T00:00:00Z",
                    "alertLevel": "error",
                    "targetType": "build",
                    "details": "secret value ghp_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX7,",
                    "decision": 2
                }
            ]
        }
    }
}
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { DiGit, DiNpm, DiGo } from "react-icons/di";


export function AlertColors() {
    return {
        success: "#339900",
        warn: "#ffcc00",
        error: "#ff9966",
        crit: "#cc3300"

    }
}

export function AlertIcon({ alert }) {
    const col = AlertColors();
    let style = {};

    style = { color: col[alert] }
    switch (alert) {
        case "crit": return (
            <Tooltip title="Critical">
                <DangerousIcon fontSize="medium" sx={style} ></ DangerousIcon>
            </Tooltip>
        )
        case "error": return (
            <Tooltip title="Error">
                <ErrorIcon fontSize="medium" sx={style} ></ ErrorIcon>
            </Tooltip>
        )
        case "warn": return (
            <Tooltip title="Warning">
                <WarningIcon fontSize="medium" ></ WarningIcon>
            </Tooltip>
        )
        default: return (
            <Tooltip title="Looks Good">
                <CheckCircleOutlineIcon fontSize="medium" sx={{ style }} ></CheckCircleOutlineIcon>
            </Tooltip>
        )
    }

}

export function EcoIcon({ eco }) {
    switch (eco) {
        case 'npm': return <DiNpm fontSize="medium"></DiNpm>
        case 'gomod': return <DiGo></DiGo>

        default: return <DiGit></DiGit>
    }
}

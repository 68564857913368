export function avgTestData() {
    return {
        isLoading: false,
        error: false,
        data: {
            "score": 5.396080686702632,
            "checks": [
                {
                    "name": "SAST",
                    "score": 0.6634995296331139
                },
                {
                    "name": "Signed-Releases",
                    "score": 0.8293906810035843
                },
                {
                    "name": "Branch-Protection",
                    "score": 1.7518675394686176
                },
                {
                    "name": "Maintained",
                    "score": 3.3335634299739225
                },
                {
                    "name": "Vulnerabilities",
                    "score": 10
                },
                {
                    "name": "Dangerous-Workflow",
                    "score": 9.90024554941682
                },
                {
                    "name": "Binary-Artifacts",
                    "score": 9.940477103628135
                },
                {
                    "name": "Code-Review",
                    "score": 4.171115201718055
                }
            ],
            "buckets": [
                {
                    "min": -1
                },
                {
                    "max": 1
                },
                {
                    "min": 1,
                    "max": 2
                },
                {
                    "min": 2,
                    "max": 3,
                    "count": 657
                },
                {
                    "min": 3,
                    "max": 4,
                    "count": 5174
                },
                {
                    "min": 4,
                    "max": 5,
                    "count": 3484
                },
                {
                    "min": 5,
                    "max": 6,
                    "count": 2343
                },
                {
                    "min": 6,
                    "max": 7,
                    "count": 1069
                },
                {
                    "min": 7,
                    "max": 8,
                    "count": 301
                },
                {
                    "min": 8,
                    "max": 9,
                    "count": 10
                },
                {
                    "min": 9,
                    "max": 10
                },
                {
                    "min": 10,
                    "max": 11
                }
            ],
            "checkBuckets": [
                {
                    "name": "Binary-Artifacts",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 1
                        },
                        {
                            "max": 1,
                            "count": 29
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 8
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5,
                            "count": 1
                        },
                        {
                            "min": 5,
                            "max": 6,
                            "count": 9
                        },
                        {
                            "min": 6,
                            "max": 7,
                            "count": 20
                        },
                        {
                            "min": 7,
                            "max": 8,
                            "count": 17
                        },
                        {
                            "min": 8,
                            "max": 9,
                            "count": 36
                        },
                        {
                            "min": 9,
                            "max": 10,
                            "count": 168
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 12749
                        }
                    ]
                },
                {
                    "name": "Branch-Protection",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 53
                        },
                        {
                            "max": 1,
                            "count": 8553
                        },
                        {
                            "min": 1,
                            "max": 2,
                            "count": 389
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 23
                        },
                        {
                            "min": 3,
                            "max": 4,
                            "count": 1646
                        },
                        {
                            "min": 4,
                            "max": 5,
                            "count": 5
                        },
                        {
                            "min": 5,
                            "max": 6,
                            "count": 1
                        },
                        {
                            "min": 6,
                            "max": 7,
                            "count": 846
                        },
                        {
                            "min": 7,
                            "max": 8,
                            "count": 10
                        },
                        {
                            "min": 8,
                            "max": 9,
                            "count": 1458
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 54
                        }
                    ]
                },
                {
                    "name": "Code-Review",
                    "bucket": [
                        {
                            "min": -1
                        },
                        {
                            "max": 1,
                            "count": 3561
                        },
                        {
                            "min": 1,
                            "max": 2,
                            "count": 1288
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 1078
                        },
                        {
                            "min": 3,
                            "max": 4,
                            "count": 825
                        },
                        {
                            "min": 4,
                            "max": 5,
                            "count": 753
                        },
                        {
                            "min": 5,
                            "max": 6,
                            "count": 684
                        },
                        {
                            "min": 6,
                            "max": 7,
                            "count": 628
                        },
                        {
                            "min": 7,
                            "max": 8,
                            "count": 531
                        },
                        {
                            "min": 8,
                            "max": 9,
                            "count": 764
                        },
                        {
                            "min": 9,
                            "max": 10,
                            "count": 825
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 2101
                        }
                    ]
                },
                {
                    "name": "Dangerous-Workflow",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 6
                        },
                        {
                            "max": 1,
                            "count": 130
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 12902
                        }
                    ]
                },
                {
                    "name": "Maintained",
                    "bucket": [
                        {
                            "min": -1
                        },
                        {
                            "max": 1,
                            "count": 7348
                        },
                        {
                            "min": 1,
                            "max": 2,
                            "count": 506
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 346
                        },
                        {
                            "min": 3,
                            "max": 4,
                            "count": 263
                        },
                        {
                            "min": 4,
                            "max": 5,
                            "count": 249
                        },
                        {
                            "min": 5,
                            "max": 6,
                            "count": 314
                        },
                        {
                            "min": 6,
                            "max": 7,
                            "count": 138
                        },
                        {
                            "min": 7,
                            "max": 8,
                            "count": 125
                        },
                        {
                            "min": 8,
                            "max": 9,
                            "count": 104
                        },
                        {
                            "min": 9,
                            "max": 10,
                            "count": 75
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 3570
                        }
                    ]
                },
                {
                    "name": "SAST",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 2408
                        },
                        {
                            "max": 1,
                            "count": 9785
                        },
                        {
                            "min": 1,
                            "max": 2,
                            "count": 3
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 12
                        },
                        {
                            "min": 3,
                            "max": 4,
                            "count": 6
                        },
                        {
                            "min": 4,
                            "max": 5,
                            "count": 5
                        },
                        {
                            "min": 5,
                            "max": 6,
                            "count": 3
                        },
                        {
                            "min": 6,
                            "max": 7,
                            "count": 3
                        },
                        {
                            "min": 7,
                            "max": 8,
                            "count": 321
                        },
                        {
                            "min": 8,
                            "max": 9,
                            "count": 35
                        },
                        {
                            "min": 9,
                            "max": 10,
                            "count": 142
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 315
                        }
                    ]
                },
                {
                    "name": "Signed-Releases",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 11643
                        },
                        {
                            "max": 1,
                            "count": 1238
                        },
                        {
                            "min": 1,
                            "max": 2,
                            "count": 4
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 3
                        },
                        {
                            "min": 3,
                            "max": 4,
                            "count": 5
                        },
                        {
                            "min": 4,
                            "max": 5,
                            "count": 2
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7,
                            "count": 10
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9,
                            "count": 133
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11
                        }
                    ]
                },
                {
                    "name": "Vulnerabilities",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 6
                        },
                        {
                            "max": 1
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11,
                            "count": 13032
                        }
                    ]
                }
            ],
            "totalRepos": 13038,
            "languages": {
                "languages": [
                    {
                        "language": "Vue",
                        "size": 10630687
                    },
                    {
                        "language": "NSIS",
                        "size": 142237
                    },
                    {
                        "language": "C#",
                        "size": 183064620
                    },
                    {
                        "language": "HTML",
                        "size": 146227310
                    },
                    {
                        "language": "Gnuplot",
                        "size": 115516
                    },
                    {
                        "language": "Scala",
                        "size": 54323
                    },
                    {
                        "language": "SaltStack",
                        "size": 5664
                    },
                    {
                        "language": "Standard ML",
                        "size": 5910423
                    },
                    {
                        "language": "GAP",
                        "size": 225554
                    },
                    {
                        "language": "Mermaid",
                        "size": 31938
                    },
                    {
                        "language": "Imba",
                        "size": 756
                    },
                    {
                        "language": "SugarSS",
                        "size": 617
                    },
                    {
                        "language": "QMake",
                        "size": 1143
                    },
                    {
                        "language": "PureBasic",
                        "size": 20821226
                    },
                    {
                        "language": "Nunjucks",
                        "size": 1313035
                    },
                    {
                        "language": "Perl",
                        "size": 2266650
                    },
                    {
                        "language": "HLSL",
                        "size": 184
                    },
                    {
                        "language": "ANTLR",
                        "size": 450784
                    },
                    {
                        "language": "Dogescript",
                        "size": 27709
                    },
                    {
                        "language": "CUE",
                        "size": 4040650
                    },
                    {
                        "language": "Dockerfile",
                        "size": 6271114
                    },
                    {
                        "language": "OCaml",
                        "size": 117276488
                    },
                    {
                        "language": "Dart",
                        "size": 6433588
                    },
                    {
                        "language": "C++",
                        "size": 138131114
                    },
                    {
                        "language": "Visual Basic .NET",
                        "size": 1930
                    },
                    {
                        "language": "Meson",
                        "size": 237461
                    },
                    {
                        "language": "EJS",
                        "size": 652420
                    },
                    {
                        "language": "Inno Setup",
                        "size": 325171
                    },
                    {
                        "language": "Fortran",
                        "size": 100
                    },
                    {
                        "language": "Liquid",
                        "size": 148992
                    },
                    {
                        "language": "Objective-C",
                        "size": 8048745
                    },
                    {
                        "language": "HCL",
                        "size": 7039481
                    },
                    {
                        "language": "RobotFramework",
                        "size": 620396
                    },
                    {
                        "language": "SmPL",
                        "size": 10748
                    },
                    {
                        "language": "SCSS",
                        "size": 36225790
                    },
                    {
                        "language": "C",
                        "size": 236733215
                    },
                    {
                        "language": "F#",
                        "size": 28858
                    },
                    {
                        "language": "AGS Script",
                        "size": 62471
                    },
                    {
                        "language": "MATLAB",
                        "size": 121960
                    },
                    {
                        "language": "Jsonnet",
                        "size": 2867772
                    },
                    {
                        "language": "Thrift",
                        "size": 937432
                    },
                    {
                        "language": "CSS",
                        "size": 127083193
                    },
                    {
                        "language": "Common Lisp",
                        "size": 41010
                    },
                    {
                        "language": "M4",
                        "size": 5716428
                    },
                    {
                        "language": "Stylus",
                        "size": 708501
                    },
                    {
                        "language": "Sage",
                        "size": 150960
                    },
                    {
                        "language": "PHP",
                        "size": 2487041
                    },
                    {
                        "language": "Monkey C",
                        "size": 9658
                    },
                    {
                        "language": "LiveScript",
                        "size": 529048
                    },
                    {
                        "language": "Fluent",
                        "size": 74142
                    },
                    {
                        "language": "Elm",
                        "size": 2525845
                    },
                    {
                        "language": "RPC",
                        "size": 84473
                    },
                    {
                        "language": "Emacs Lisp",
                        "size": 109659
                    },
                    {
                        "language": "Gherkin",
                        "size": 6127232
                    },
                    {
                        "language": "Cairo",
                        "size": 217496
                    },
                    {
                        "language": "VBA",
                        "size": 28287
                    },
                    {
                        "language": "Cython",
                        "size": 4794028
                    },
                    {
                        "language": "Rich Text Format",
                        "size": 73116
                    },
                    {
                        "language": "Logos",
                        "size": 698626
                    },
                    {
                        "language": "Lex",
                        "size": 42607
                    },
                    {
                        "language": "Mako",
                        "size": 494
                    },
                    {
                        "language": "Cuda",
                        "size": 3634
                    },
                    {
                        "language": "Cap'n Proto",
                        "size": 19571
                    },
                    {
                        "language": "Terra",
                        "size": 422
                    },
                    {
                        "language": "GDB",
                        "size": 1614
                    },
                    {
                        "language": "Elvish",
                        "size": 2304
                    },
                    {
                        "language": "CoffeeScript",
                        "size": 6980471
                    },
                    {
                        "language": "Python",
                        "size": 427216004
                    },
                    {
                        "language": "Twig",
                        "size": 12987
                    },
                    {
                        "language": "Vim Script",
                        "size": 63906
                    },
                    {
                        "language": "Procfile",
                        "size": 492054
                    },
                    {
                        "language": "Smalltalk",
                        "size": 29594
                    },
                    {
                        "language": "Nim",
                        "size": 95777
                    },
                    {
                        "language": "Awk",
                        "size": 45611
                    },
                    {
                        "language": "Lua",
                        "size": 855484
                    },
                    {
                        "language": "Smithy",
                        "size": 15507
                    },
                    {
                        "language": "Swift",
                        "size": 2734018
                    },
                    {
                        "language": "Vim Snippet",
                        "size": 717
                    },
                    {
                        "language": "ASP.NET",
                        "size": 825
                    },
                    {
                        "language": "TeX",
                        "size": 614497
                    },
                    {
                        "language": "PureScript",
                        "size": 1190154
                    },
                    {
                        "language": "PowerShell",
                        "size": 20136719
                    },
                    {
                        "language": "Shell",
                        "size": 75119776
                    },
                    {
                        "language": "Jinja",
                        "size": 1054250
                    },
                    {
                        "language": "Raku",
                        "size": 5853
                    },
                    {
                        "language": "VCL",
                        "size": 93394
                    },
                    {
                        "language": "JavaScript",
                        "size": 1670979912
                    },
                    {
                        "language": "M",
                        "size": 18628
                    },
                    {
                        "language": "GLSL",
                        "size": 40613
                    },
                    {
                        "language": "Tcl",
                        "size": 191785
                    },
                    {
                        "language": "Dhall",
                        "size": 1747
                    },
                    {
                        "language": "Solidity",
                        "size": 3979253
                    },
                    {
                        "language": "Svelte",
                        "size": 928298
                    },
                    {
                        "language": "CMake",
                        "size": 3427078
                    },
                    {
                        "language": "Clojure",
                        "size": 4500
                    },
                    {
                        "language": "ReScript",
                        "size": 9419323
                    },
                    {
                        "language": "Vala",
                        "size": 74394
                    },
                    {
                        "language": "Rust",
                        "size": 18530836
                    },
                    {
                        "language": "DTrace",
                        "size": 653
                    },
                    {
                        "language": "FreeMarker",
                        "size": 24242
                    },
                    {
                        "language": "Perl 6",
                        "size": 190
                    },
                    {
                        "language": "D",
                        "size": 720508
                    },
                    {
                        "language": "ShaderLab",
                        "size": 660
                    },
                    {
                        "language": "Marko",
                        "size": 742451
                    },
                    {
                        "language": "Reason",
                        "size": 272496
                    },
                    {
                        "language": "Assembly",
                        "size": 13728450
                    },
                    {
                        "language": "PLpgSQL",
                        "size": 6488707
                    },
                    {
                        "language": "Arc",
                        "size": 184
                    },
                    {
                        "language": "PostScript",
                        "size": 23811
                    },
                    {
                        "language": "R",
                        "size": 4722980
                    },
                    {
                        "language": "Slim",
                        "size": 7688
                    },
                    {
                        "language": "Pug",
                        "size": 388634
                    },
                    {
                        "language": "Smarty",
                        "size": 1936053
                    },
                    {
                        "language": "Haml",
                        "size": 38
                    },
                    {
                        "language": "sed",
                        "size": 11477
                    },
                    {
                        "language": "Groovy",
                        "size": 4104121
                    },
                    {
                        "language": "Handlebars",
                        "size": 9962024
                    },
                    {
                        "language": "ActionScript",
                        "size": 25864
                    },
                    {
                        "language": "JSON",
                        "size": 34108230
                    },
                    {
                        "language": "NASL",
                        "size": 111052
                    },
                    {
                        "language": "Roff",
                        "size": 2292080
                    },
                    {
                        "language": "TLA",
                        "size": 1067572
                    },
                    {
                        "language": "TSQL",
                        "size": 11855
                    },
                    {
                        "language": "APL",
                        "size": 127975
                    },
                    {
                        "language": "WebAssembly",
                        "size": 40520326
                    },
                    {
                        "language": "PEG.js",
                        "size": 104099
                    },
                    {
                        "language": "Go",
                        "size": 10535826348
                    },
                    {
                        "language": "Ragel",
                        "size": 1337280
                    },
                    {
                        "language": "Pascal",
                        "size": 619239
                    },
                    {
                        "language": "Sass",
                        "size": 202783
                    },
                    {
                        "language": "Objective-C++",
                        "size": 851288
                    },
                    {
                        "language": "Jupyter Notebook",
                        "size": 150510
                    },
                    {
                        "language": "Yacc",
                        "size": 2908893
                    },
                    {
                        "language": "XSLT",
                        "size": 172572
                    },
                    {
                        "language": "Astro",
                        "size": 795
                    },
                    {
                        "language": "Java",
                        "size": 570619412
                    },
                    {
                        "language": "Kotlin",
                        "size": 2349338
                    },
                    {
                        "language": "Julia",
                        "size": 30110
                    },
                    {
                        "language": "Less",
                        "size": 4617686
                    },
                    {
                        "language": "WebIDL",
                        "size": 264330
                    },
                    {
                        "language": "Nix",
                        "size": 46367
                    },
                    {
                        "language": "Haxe",
                        "size": 339328
                    },
                    {
                        "language": "Scilab",
                        "size": 54706
                    },
                    {
                        "language": "Hack",
                        "size": 141
                    },
                    {
                        "language": "AMPL",
                        "size": 1447
                    },
                    {
                        "language": "BASIC",
                        "size": 852
                    },
                    {
                        "language": "Brainfuck",
                        "size": 40170
                    },
                    {
                        "language": "AppleScript",
                        "size": 5382
                    },
                    {
                        "language": "DIGITAL Command Language",
                        "size": 3422
                    },
                    {
                        "language": "Bicep",
                        "size": 293223
                    },
                    {
                        "language": "Puppet",
                        "size": 41613
                    },
                    {
                        "language": "Ruby",
                        "size": 13737861
                    },
                    {
                        "language": "TypeScript",
                        "size": 16966532195
                    },
                    {
                        "language": "Elixir",
                        "size": 1017940
                    },
                    {
                        "language": "FLUX",
                        "size": 5653699
                    },
                    {
                        "language": "Batchfile",
                        "size": 573060
                    },
                    {
                        "language": "Nearley",
                        "size": 10592
                    },
                    {
                        "language": "jq",
                        "size": 54881
                    },
                    {
                        "language": "API Blueprint",
                        "size": 725
                    },
                    {
                        "language": "Haskell",
                        "size": 3284645
                    },
                    {
                        "language": "Earthly",
                        "size": 1738
                    },
                    {
                        "language": "Mustache",
                        "size": 1631633
                    },
                    {
                        "language": "Makefile",
                        "size": 21293846
                    },
                    {
                        "language": "Erlang",
                        "size": 361483
                    },
                    {
                        "language": "Starlark",
                        "size": 20905480
                    },
                    {
                        "language": "Open Policy Agent",
                        "size": 565051
                    }
                ]
            }
        }
    }
}

export default function testData() {


    return {
        "repoPath": "github.com/invisirisk/clog",
        "alertLevel": "error",
        "dependencies": [
            {
                "alertLevel": "error",
                "score": 3.75,
                "Path": "go.mod",
                "Ecosystem": "gomod",
                "Dependencies": [
                    {
                        "Name": "github.com/golang/glog",
                        "Path": "",
                        "Version": "1.0.0",
                        "Ecosystem": "",
                        "score": {
                            "score": 3.75,
                            "checks": [
                                {
                                    "alertLevel": "",
                                    "name": "Binary-Artifacts",
                                    "details": null,
                                    "score": 10,
                                    "reason": "no binaries found in the repo",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#binary-artifacts",
                                        "Short": "Determines if the project has generated executable (binary) artifacts in the source repository."
                                    }
                                },
                                {
                                    "alertLevel": "error",
                                    "name": "Branch-Protection",
                                    "details": [
                                        "Warn: branch protection not enabled for branch 'master'"
                                    ],
                                    "score": 0,
                                    "reason": "branch protection not enabled on development/release branches",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#branch-protection",
                                        "Short": "Determines if the default and release branches are protected with GitHub's branch protection settings."
                                    }
                                },
                                {
                                    "alertLevel": "warn",
                                    "name": "Code-Review",
                                    "details": [
                                        "Warn: no reviews found for commit: 1c82741128292f849b1323fd295b0cd21a22c209",
                                        "Warn: no reviews found for commit: 23def4e6c14b4da8ac2ed8007337bc5eb5007998",
                                        "Warn: no reviews found for commit: fca8c8854093a154ff1eb580aae10276ad6b1b5f",
                                        "Warn: no reviews found for commit: 65d674618f712aa808a7d0104131b9206fc3d5ad",
                                        "Warn: no reviews found for commit: 44145f04b68cf362d9c4df2182967c2275eaefed",
                                        "Warn: no reviews found for commit: c56a6cb113f06762a32067a951f65caee77ddadd",
                                        "Warn: no reviews found for commit: c741557f8f77751e253ad5a27b6e3f70391aff08",
                                        "Warn: no reviews found for commit: b83197c33c35da605bf2be628a4a3ed7695e21d6",
                                        "Warn: no reviews found for commit: 5066b11157b3bdeecde64806bf298cabad703a8c",
                                        "Warn: no reviews found for commit: 49850ea55f4b32f6c605a10784fdd6f5651f0fce",
                                        "Warn: no reviews found for commit: d1c4472bf2efd3826f2b5bdcc02d8416798d678c",
                                        "Warn: no reviews found for commit: 20cea4dfef0c0151bba0422a6244cc1d3e8292b1",
                                        "Warn: no reviews found for commit: c6f9652c7179652e2fd8ed7002330db089f4c9db",
                                        "Warn: no reviews found for commit: 228ba85797643dc4b25093bd3386f51b77103ea3",
                                        "Warn: no reviews found for commit: f74c4c6b9610b9a36eeea83b7c18c9320b7fcc8d",
                                        "Warn: no reviews found for commit: ab6a54c30dfa6835c4251a801a089bebcbfd461c",
                                        "Warn: no reviews found for commit: c2ab0c9a9bad2bd7d56d34b919f9d737e4a3822d",
                                        "Warn: no reviews found for commit: 47832e5afed51b27488a82c5fc1a8f83dc73f2ba",
                                        "Warn: no reviews found for commit: 029e58eb9ea7a4be928582fb9f604c5e2557a0cf",
                                        "Warn: no reviews found for commit: 496d3b747b2851cc98f4838bcfa59f707c4cee25",
                                        "Warn: no reviews found for commit: 2b6b49888830bc4add903e415386fc24afa3797c",
                                        "Warn: no reviews found for commit: 3e682cc72cac34d315430d66d4af375cbec079c5",
                                        "Warn: no reviews found for commit: 2d42ba6cf5d66a0098e7ba07a69cd5ab6232e199"
                                    ],
                                    "score": 1,
                                    "reason": "GitHub code reviews found for 4 commits out of the last 27 -- score normalized to 1",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#code-review",
                                        "Short": "Determines if the project requires code review before pull requests (aka merge requests) are merged."
                                    }
                                },
                                {
                                    "alertLevel": "",
                                    "name": "Dangerous-Workflow",
                                    "details": null,
                                    "score": 10,
                                    "reason": "no dangerous workflow patterns detected",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#dangerous-workflow",
                                        "Short": "Determines if the project's GitHub Action workflows avoid dangerous patterns."
                                    }
                                },
                                {
                                    "alertLevel": "warn",
                                    "name": "Maintained",
                                    "details": null,
                                    "score": 0,
                                    "reason": "0 commit(s) out of 27 and 0 issue activity out of 0 found in the last 90 days -- score normalized to 0",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#maintained",
                                        "Short": "Determines if the project is \"actively maintained\"."
                                    }
                                },
                                {
                                    "alertLevel": "warn",
                                    "name": "SAST",
                                    "details": [
                                        "Warn: 0 commits out of 6 are checked with a SAST tool",
                                        "Warn: CodeQL tool not detected"
                                    ],
                                    "score": 0,
                                    "reason": "SAST tool is not run on all commits -- score normalized to 0",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#sast",
                                        "Short": "Determines if the project uses static code analysis."
                                    }
                                },
                                {
                                    "alertLevel": "",
                                    "name": "Signed-Releases",
                                    "details": [
                                        "Warn: no GitHub releases found"
                                    ],
                                    "score": -1,
                                    "reason": "no releases found",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#signed-releases",
                                        "Short": "Determines if the project cryptographically signs release artifacts."
                                    }
                                },
                                {
                                    "alertLevel": "",
                                    "name": "Vulnerabilities",
                                    "details": null,
                                    "score": 10,
                                    "reason": "no vulnerabilities detected",
                                    "Documentation": {
                                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#vulnerabilities",
                                        "Short": "Determines if the project has open, known unfixed vulnerabilities."
                                    }
                                }
                            ],
                            "alertLevel": "error"
                        }
                    }
                ]
            }
        ],
        "selfScore": {
            "score": 3.625,
            "checks": [
                {
                    "alertLevel": "",
                    "name": "Binary-Artifacts",
                    "details": null,
                    "score": 10,
                    "reason": "no binaries found in the repo",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#binary-artifacts",
                        "Short": "Determines if the project has generated executable (binary) artifacts in the source repository."
                    }
                },
                {
                    "alertLevel": "error",
                    "name": "Branch-Protection",
                    "details": [
                        "Warn: branch protection not enabled for branch 'main'"
                    ],
                    "score": 0,
                    "reason": "branch protection not enabled on development/release branches",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#branch-protection",
                        "Short": "Determines if the default and release branches are protected with GitHub's branch protection settings."
                    }
                },
                {
                    "alertLevel": "warn",
                    "name": "Code-Review",
                    "details": [
                        "Warn: no reviews found for commit: 30f9b09ca67b1c3c0ee1d8a6034ccfb97be333e8",
                        "Warn: no reviews found for commit: 8ef11b5bd47be7c50bfe614bb3e2f25912383ab7",
                        "Warn: no reviews found for commit: 3e25c034f0b4e351144dfcbfb3838f17ebe1ef2b"
                    ],
                    "score": 0,
                    "reason": "no reviews found",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#code-review",
                        "Short": "Determines if the project requires code review before pull requests (aka merge requests) are merged."
                    }
                },
                {
                    "alertLevel": "",
                    "name": "Dangerous-Workflow",
                    "details": null,
                    "score": 10,
                    "reason": "no dangerous workflow patterns detected",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#dangerous-workflow",
                        "Short": "Determines if the project's GitHub Action workflows avoid dangerous patterns."
                    }
                },
                {
                    "alertLevel": "warn",
                    "name": "Maintained",
                    "details": null,
                    "score": 0,
                    "reason": "0 commit(s) out of 3 and 0 issue activity out of 0 found in the last 90 days -- score normalized to 0",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#maintained",
                        "Short": "Determines if the project is \"actively maintained\"."
                    }
                },
                {
                    "alertLevel": "warn",
                    "name": "SAST",
                    "details": [
                        "Warn: no pull requests merged into dev branch",
                        "Warn: CodeQL tool not detected"
                    ],
                    "score": 0,
                    "reason": "no SAST tool detected",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#sast",
                        "Short": "Determines if the project uses static code analysis."
                    }
                },
                {
                    "alertLevel": "",
                    "name": "Signed-Releases",
                    "details": [
                        "Warn: no GitHub releases found"
                    ],
                    "score": -1,
                    "reason": "no releases found",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#signed-releases",
                        "Short": "Determines if the project cryptographically signs release artifacts."
                    }
                },
                {
                    "alertLevel": "",
                    "name": "Vulnerabilities",
                    "details": null,
                    "score": 10,
                    "reason": "no vulnerabilities detected",
                    "Documentation": {
                        "Url": "https://github.com/ossf/scorecard/blob/2cbf5afd5460b51fd40939f8c44b32543b1a0bcb/docs/checks.md#vulnerabilities",
                        "Short": "Determines if the project has open, known unfixed vulnerabilities."
                    }
                }
            ],
            "alertLevel": "error"
        },
        "averageScore": 3.625,
        "alerts": [
            {
                "created": "0001-01-01T00:00:00Z",
                "cleared": "0001-01-01T00:00:00Z",
                "alertLevel": "error",
                "targetType": "repo",
                "target": "github.com/invisirisk/clog",
                "details": "package \"github.com/invisirisk/clog\" has very low security score of 3.625"
            },
            {
                "created": "0001-01-01T00:00:00Z",
                "cleared": "0001-01-01T00:00:00Z",
                "alertLevel": "error",
                "targetType": "repo",
                "target": "github.com/golang/glog",
                "ecosystem": "gomod",
                "details": "package \"github.com/golang/glog\" has very low security score of 3.75"
            }
        ],
        "analytics": {
            "buckets": [
                {
                    "name": "Dependency Score",
                    "bucket": [
                        {
                            "min": -1
                        },
                        {
                            "max": 1
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3,
                            "count": 1
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11
                        }
                    ]
                },
                {
                    "name": "Dangerous-Workflow",
                    "bucket": [
                        {
                            "min": -1
                        },
                        {
                            "max": 1
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10,
                            "count": 1
                        },
                        {
                            "min": 10,
                            "max": 11
                        }
                    ]
                },
                {
                    "name": "Code-Review",
                    "bucket": [
                        {
                            "min": -1
                        },
                        {
                            "max": 1,
                            "count": 1
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11
                        }
                    ]
                },
                {
                    "name": "Branch-Protection",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 1
                        },
                        {
                            "max": 1
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11
                        }
                    ]
                },
                {
                    "name": "Maintained",
                    "bucket": [
                        {
                            "min": -1,
                            "count": 1
                        },
                        {
                            "max": 1
                        },
                        {
                            "min": 1,
                            "max": 2
                        },
                        {
                            "min": 2,
                            "max": 3
                        },
                        {
                            "min": 3,
                            "max": 4
                        },
                        {
                            "min": 4,
                            "max": 5
                        },
                        {
                            "min": 5,
                            "max": 6
                        },
                        {
                            "min": 6,
                            "max": 7
                        },
                        {
                            "min": 7,
                            "max": 8
                        },
                        {
                            "min": 8,
                            "max": 9
                        },
                        {
                            "min": 9,
                            "max": 10
                        },
                        {
                            "min": 10,
                            "max": 11
                        }
                    ]
                }
            ]
        }
    }
}


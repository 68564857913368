import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';


export function ScoreChart({ value }) {
    let col = "secondary"
    if (value < 5 && value >= 0) {
        col = "primary"

    }
    let val = value * 10

    if (value < 0) {
        val = 0
    }
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress color={col} variant="determinate" value={val} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,

                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color={col}>
                    {`${Math.round(value)}`}
                </Typography>
            </Box>
        </Box>
    );
}

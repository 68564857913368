import IrDahshboardLayout from "components/IrDashboard";

import { TechChecks } from "examples/Technologies";
import { useLocation } from 'react-router-dom'

function TechCheckTable() {
    let location = useLocation();

    return (
        <IrDahshboardLayout>
            <TechChecks checks={location.state.data.checks}></TechChecks>
        </IrDahshboardLayout>
    )

}


export default TechCheckTable
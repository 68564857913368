
import TreeView from '@mui/lab/TreeView';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { AlertIcon } from 'components/Icons';
import { ScoreChart } from 'components/ScoreChart';
import Checks from 'components/Checks';


const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        alertLevel,
        ...other
    } = props;


    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, flexGrow: 1 }} >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1, p: 0.5 }} />
                    <Box sx={{ p: 0.5 }}>
                        <AlertIcon alert={alertLevel}></AlertIcon>
                    </Box>
                    <Box sx={{ fontWeight: 'inherit', flexGrow: 1, p: 0.5 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            {labelText}
                        </Typography>
                    </Box>
                    {labelInfo && (
                        <>
                            <Typography variant="body2" m={1} color='inherit' sx={{ fontWeight: 'inherit' }}>
                                Score:
                            </Typography>
                            <ScoreChart value={labelInfo}></ScoreChart>
                        </>
                    )
                    }
                </ Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}


export default function Dependencies({ scoreData }) {

    let selfScore = scoreData.selfScore;

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            <StyledTreeItem nodeId={uuidv4()} labelText={"self"} labelInfo={selfScore.score} alertLevel={selfScore.alertLevel}>
                <Checks checks={selfScore.checks}></Checks>
            </StyledTreeItem>
            {
                scoreData.dependencies.map(d => {
                    return (
                        <StyledTreeItem nodeId={uuidv4()} labelText={d.Path} alertLevel={d.alertLevel} labelInfo={d.score}>
                            {
                                d.Dependencies.map(dd => {
                                    if (dd.score) {
                                        return (
                                            < StyledTreeItem nodeId={uuidv4()} labelText={dd.Name + "@" + dd.Version} labelInfo={dd.score.score} alertLevel={dd.score.alertLevel}>
                                                <Checks checks={dd.score.checks}></Checks>
                                            </StyledTreeItem>
                                        )
                                    } else
                                        return (
                                            < StyledTreeItem nodeId={uuidv4()} labelText={dd.Name + "@" + dd.Version} />
                                        )
                                })
                            }
                        </StyledTreeItem>
                    )
                })
            }
        </TreeView>
    )
}
